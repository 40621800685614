import React, { useState, useEffect , useCallback } from 'react';
import { VStack, HStack, Input, Button, Box, Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/react';
import ColorPalette from './ColorPalette';
import { debounce } from 'lodash';

export interface LabelOption {
  label: string;
  color: string;
}

interface LabelOptionEditorProps {
  options: LabelOption[];
  onChange: (newOptions: LabelOption[]) => void;
}

interface LabelInputProps {
  handleLabelChange: (index: number, newLabel: string) => void;
  index: number,
  option: LabelOption
}

const LabelOptionEditor: React.FC<LabelOptionEditorProps> = ({ options, onChange }) => {
  const [localOptions, setLocalOptions] = useState<LabelOption[]>(() => {
    return Array.isArray(options) ? options : [];
  });
  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);

  useEffect(() => {
    if (Array.isArray(options)) {
      setLocalOptions(options);
    }
  }, [options]);

  const handleLabelChange = (index: number, newLabel: string) => {
    const newOptions = [...localOptions];
    newOptions[index] = { ...newOptions[index], label: newLabel };
    setLocalOptions(newOptions);
    onChange(newOptions);
  };

  const handleColorChange = (index: number, newColor: string) => {
    const newOptions = [...localOptions];
    newOptions[index] = { ...newOptions[index], color: newColor };
    setLocalOptions(newOptions);
    onChange(newOptions);
    setOpenPopoverIndex(null);
  };

  const addOption = () => {
    setLocalOptions([...localOptions, { label: '', color: '#000000' }]);
    onChange([...localOptions, { label: '', color: '#000000' }]);
  };

  const removeOption = (index: number) => {
    const newOptions = localOptions.filter((_, i) => i !== index);
    setLocalOptions(newOptions);
    onChange(newOptions);
  };

  return (
    <VStack spacing={4} align="stretch">
      {localOptions.map((option, index) => (
        <VStack key={index} spacing={2} align="stretch">
          <HStack>
            <LabelInput index={index} option={option} handleLabelChange={handleLabelChange}/>
            <Popover
              isOpen={openPopoverIndex === index}
              onClose={() => setOpenPopoverIndex(null)}
            >
              <PopoverTrigger>
                <Button
                  size="sm"
                  width="40px"
                  height="40px"
                  padding="0"
                  onClick={() => setOpenPopoverIndex(index)}
                >
                  <Box width="20px" height="20px" borderRadius="50%" backgroundColor={option.color} />
                </Button>
              </PopoverTrigger>
              <PopoverContent width="220px">
                <PopoverBody>
                  <ColorPalette
                    selectedColor={option.color}
                    onColorSelect={(color) => handleColorChange(index, color)}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Button onClick={() => removeOption(index)} size="sm" fontWeight="normal">
              Remove
            </Button>
          </HStack>
        </VStack>
      ))}
      <Button onClick={addOption} size="sm" colorScheme="black" variant="outline" fontWeight="normal">
        Add Option
      </Button>
    </VStack>
  );
};


const LabelInput = (props: LabelInputProps) => {
  const { handleLabelChange, option, index } = props;
  const [label , setLabel] = useState<string>(option.label);
  const debouncedHandleLabelChange = useCallback(debounce((index, value) => handleLabelChange(index, value), 500), []);

  return (
    <Input
      value={label}
      onChange={(e) => {
        setLabel(e.target.value);
        debouncedHandleLabelChange(index, e.target.value);
      }}
      placeholder="Enter label 1"
    />
  )
}
export default LabelOptionEditor;
