import React, { useEffect, useState } from 'react';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { zoneService } from 'services/zoneService';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ 
        backgroundColor: '#fff', // Change this to your desired background color
        border: '1px solid #861B81',
        padding: '10px',
        borderRadius: '5px'
      }}>
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

interface BarChartProps {
  zoneId: string;
  xAxis: string;
  yAxis: string;
}

const BarChart: React.FC<BarChartProps> = ({ zoneId, xAxis, yAxis }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const zoneData = await zoneService.getZoneById(zoneId);

        if (zoneData.data.type === 'table') {
          // For table-type zones, use the data directly from zoneData
          const tableData = zoneData.data.data || [];

          if (!Array.isArray(tableData) || tableData.length === 0) {
            throw new Error('No items found for the given zoneId');
          }

          const chartData = tableData.map((item: any) => {
            const xValue = item[xAxis] || 'Unknown';
            const yValue = parseFloat(item[yAxis]) || 0;
            return {
              name: xValue,
              value: yValue
            };
          });

          setData(chartData);
        } else {
          // Existing logic for non-table zones
          const zoneItems = await zoneService.getZoneItems(zoneId);

          if (!Array.isArray(zoneItems) || zoneItems.length === 0) {
            throw new Error('No items found for the given zoneId');
          }

          const chartData = zoneItems.map((item: any) => {
            const xValue = item.data[xAxis]?.value || item.data[xAxis] || 'Unknown';
            const yValue = parseFloat(item.data[yAxis]?.value || item.data[yAxis]) || 0;
            return {
              name: xValue,
              value: yValue
            };
          });

          setData(chartData);
        }
      } catch (error) {
        console.error('Error fetching zone items:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };


      fetchData()

  }, [zoneId, xAxis, yAxis]);

  if (isLoading) {
    return <div>Loading chart data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (data.length === 0) {
    return <div>No data available for the selected axes</div>;
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsBarChart data={data}>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />}
        cursor={{ fill: "none" }} 
        />
        
        <Legend />
        
        <Bar 
        //stroke="#000000"
        dataKey="value" 
        fill="#861B81"
        activeBar={{ fill: '#ff7300' }}
         />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;