import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  useColorModeValue,
  Select,
  Button,
  Flex,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { zoneService } from '../../../../services/zoneService';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addZones, renameZone, deleteZone, setActiveZone } from 'store/slices/zoneSlice';
import { MenuDots } from 'components/icons/Icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { EditIcon, DeleteIcon, LockIcon } from '@chakra-ui/icons';

interface Field {
  id: string;
  type: string;
  label: string;
  appearInTable: boolean;
  required: boolean;
  tabId: string;
  order: number;
  position: string;
}

interface ZoneData {
  _id?: string;
  name: string;
  type: string;
  availableUsers: string[];
  fields: Field[];
  tabs: { id: string; name: string; content: string }[];
  companyId: string
}

export default function Zones({ setCurrentZoneName }: { setCurrentZoneName: (name: string) => void }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [zoneName, setZoneName] = useState('');
  const [zoneType, setZoneType] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allZones, setAllZones] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const dispatch = useDispatch();
  const toast = useToast();
  const companyId = useAuth()?.user?.companyId;
  const userType = useAuth().user.role;
  const userEmail = useAuth().user.email;
  
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState<string | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [isRenameAlertOpen, setIsRenameAlertOpen] = useState(false);
  const [zoneToRename, setZoneToRename] = useState<{id: string, name: string} | null>(null);
  const [newZoneName, setNewZoneName] = useState('');

  useEffect(() => {
    fetchZones();
    fetchUsers();
    setCurrentZoneName("Zone");
  }, [setCurrentZoneName]);

  const fetchZones = async () => {
    
    const zones = await zoneService.getAllZones(companyId , userType , userEmail);
  console.log("CURR_ZONE" , {companyId , userType , userEmail})
    dispatch(addZones(zones));
    setAllZones(zones);
  };

  const fetchUsers = async () => {
    try {
      const users = await zoneService.getSelectUsers();
      setAllUsers(users);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching users:', (error as any).response?.data || error.message);
      } else {
        console.error('Error fetching users:', error);
      }
    }
  };

  const handleCreateZone = async () => {
    if (!zoneName.trim() || !zoneType) {
      toast({
        title: "Error",
        description: "Zone name and type are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newZone: Omit<ZoneData, '_id'> = {
      name: zoneName.trim(),
      type: zoneType,
      availableUsers: selectedUsers,
      tabs: [{ id: 'default', name: 'Main', content: '' }],
      fields: [{
        id: 'default-title',
        type: 'Text',
        label: 'Title/Name',
        appearInTable: true,
        required: true,
        tabId: 'default',
        order: 0,
        position: 'left'
      }],
      companyId
    };

    try {
      const createdZone = await zoneService.createZone(newZone);
      dispatch(addZones(createdZone));
      setAllZones([...allZones, createdZone]);
      setZoneName('');
      setZoneType('');
      setSelectedUsers([]);

      toast({
        title: "Zone created",
        description: "New zone successfully created!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating zone:', error);
      toast({
        title: "Error",
        description: "Failed to create new zone. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUserSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUser = e.target.value;
    if (selectedUser && !selectedUsers.includes(selectedUser)) {
      setSelectedUsers([...selectedUsers, selectedUser]);
    }
  };

  const removeUser = (user: string) => {
    setSelectedUsers(selectedUsers.filter(u => u !== user));
  };

  const handleRename = async (zoneId: string, newName: string) => {
    try {
      const response = await zoneService.renameZone(zoneId, newName);
      if (response) {
        setAllZones(prevZones =>
          prevZones.map(zone =>
            zone._id === zoneId ? { ...zone, name: newName } : zone
          )
        );
        
        dispatch(renameZone({ id: zoneId, newName }));
        setCurrentZoneName(newName);

        toast({
          title: "Success",
          description: "Zone renamed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error renaming zone:', error);
      toast({
        title: "Error",
        description: "Failed to rename zone. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRenameAlertOpen(false);
      setZoneToRename(null);
      setNewZoneName('');
    }
  };

  const handleDelete = (zoneId: string) => {
    setZoneToDelete(zoneId);
    onOpen();
  };

  const confirmDelete = async () => {
    if (zoneToDelete) {
      try {
        await zoneService.deleteZone(zoneToDelete);
        setAllZones(prevZones => prevZones.filter(zone => zone._id !== zoneToDelete));
        dispatch(deleteZone(zoneToDelete));
        toast({
          title: "Zone deleted",
          description: "The zone and all associated reports and dashboard panels have been successfully deleted.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting zone:', error);
        toast({
          title: "Error",
          description: "Failed to delete the zone. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        onClose();
        setZoneToDelete(null);
      }
    }
  };

  const handlePermissions = (zoneId: string) => {
    // Implement permissions logic
    console.log('Manage permissions for zone:', zoneId);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="row" gap="20px">
        <Card maxWidth="50%" height="calc(100vh - 128px)" overflowY="auto">
          <Text fontSize="2xl" fontWeight="bold" mb={4}>All Zones</Text>
          <Text fontSize="sm" mb={4}>Edit and manage your zones.</Text>
          <SimpleGrid columns={1} spacing={4}>
            {allZones.map((zone) => (
              <Card key={zone._id} p={4}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Link to={`/admin/zones/${zone._id}?type=${zone.type}`} style={{ textDecoration: 'none' }} onClick={()=>dispatch(setActiveZone(zone.name))}>
                    <Text fontWeight="600" fontSize="lg" _hover={{ textDecoration: 'underline' }}>{zone.name}</Text>
                  </Link>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label='Options'
                      icon={<MenuDots boxSize={6} />}
                      variant='ghost'
                      size='sm'
                      _hover={{
                        bg: "gray.200"
                      }} 
                    />
                    <MenuList className='smallMenuDrop'>
                      <MenuItem 
                        onClick={() => {
                          setZoneToRename({id: zone._id, name: zone.name});
                          setNewZoneName(zone.name);
                          setIsRenameAlertOpen(true);
                        }}
                        icon={<Icon as={EditIcon} color="orange.500" />}
                      >
                        Rename
                      </MenuItem>
                      <MenuItem 
                        onClick={() => handleDelete(zone._id)}
                        icon={<Icon as={DeleteIcon} color="orange.500" />}
                      >
                        Delete
                      </MenuItem>
                      <MenuItem 
                        onClick={() => handlePermissions(zone._id)}
                        icon={<Icon as={LockIcon} color="orange.500" />}
                      >
                        Permissions
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
                <Flex flexWrap="wrap" gap={2}>
                  {zone.availableUsers.map((user: string, index: number) => (
                    <Tag key={index} size="sm" bg="orange.500" color="white">
                      <TagLabel>{user}</TagLabel>
                    </Tag>
                  ))}
                </Flex>
              </Card>
            ))}
          </SimpleGrid>
        </Card>
        
        <Card maxWidth="50%">
          <Text fontSize='2xl' fontWeight='700' mb='20px'>
            Create new zone
          </Text>
          <Text fontSize="sm" mb={4}>Add new zones and set permissions</Text>
          <Flex direction='column' gap='20px'>
            <Flex gap='20px'>
              <FormControl flex={1} isRequired>
                <FormLabel>Zone Name</FormLabel>
                <Input
                  placeholder='Zone Name'
                  value={zoneName}
                  onChange={(e) => setZoneName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl flex={1} isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder='Please select'
                  value={zoneType}
                  onChange={(e) => setZoneType(e.target.value)}
                  required
                >
                  <option value='table'>Data Table</option>
                  <option value='tabs'>Data Sections</option>
                </Select>
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel>Available to users</FormLabel>
              <Flex>
                <Select 
                  placeholder="Select user" 
                  onChange={handleUserSelect}
                  flex="1"
                  mr={2}
                >
                  {allUsers
                    .filter((user) => user.role !== 'admin')
                    .map((user, idx) => (
                      <option key={user.username + idx} value={user.username}>
                        {user.username}
                      </option>
                    ))
                  }
                </Select>
                <Button colorScheme='brand' onClick={handleCreateZone}>
                  CREATE
                </Button>
              </Flex>
              <Flex flexWrap='wrap' gap='8px' mt='10px'>
                {selectedUsers.map((user) => (
                  <Tag key={user} borderRadius='full' variant='solid' colorScheme='orange'>
                    <TagLabel>{user}</TagLabel>
                    <TagCloseButton onClick={() => removeUser(user)} />
                  </Tag>
                ))}
              </Flex>
            </FormControl>
          </Flex>
        </Card>
      </Flex>

      {isOpen && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Zone
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this zone? This action cannot be undone and will also delete all associated reports and dashboard panels.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {isRenameAlertOpen && (
        <AlertDialog
          isOpen={isRenameAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setIsRenameAlertOpen(false);
            setZoneToRename(null);
            setNewZoneName('');
          }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Rename Zone
              </AlertDialogHeader>

              <AlertDialogBody>
                <FormControl>
                  <FormLabel>New zone name</FormLabel>
                  <Input
                    value={newZoneName}
                    onChange={(e) => setNewZoneName(e.target.value)}
                    placeholder="Enter new name"
                    autoFocus
                  />
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button 
                  ref={cancelRef} 
                  onClick={() => {
                    setIsRenameAlertOpen(false);
                    setZoneToRename(null);
                    setNewZoneName('');
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  colorScheme="brand" 
                  onClick={() => {
                    if (zoneToRename && newZoneName.trim()) {
                      handleRename(zoneToRename.id, newZoneName.trim());
                    }
                  }}
                  ml={3}
                >
                  Rename
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

    </Box>
  );
}