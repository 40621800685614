import React from 'react';
import {
  Box,
  Flex,
  Th,
  Popover,
  PopoverTrigger,
  Icon,
} from '@chakra-ui/react';
import { DragHandleIcon} from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
//import styled from 'styled-components';

/* const DropIndicator = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--chakra-colors-orange-500);
  transform: translateX(-50%);
`; */

const SortableHeader = ({ 
    header, 
    children, 
    borderColor, 
    isHovered, 
    onMouseEnter, 
    onMouseLeave,
    over
  }: { 
    header: any; 
    children: React.ReactNode; 
    borderColor: string;
    isHovered: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    over?: { id: string } | null;
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      //isDragging,
    } = useSortable({
      id: header.id,
    });
  
  
    return (
      <Th
        ref={setNodeRef}
        {...attributes}
        key={header.id}
        colSpan={header.colSpan}
        padding="0"
        borderColor={borderColor}
        borderWidth="1px"
        borderStyle="solid"
        width={header.getSize()}
        position="relative"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        fontSize="md" 
        color="gray.700"
        bg={over?.id === header.id ? 'orange.50' : 'white'}
        transition="background-color 0.2s"
        sx={{
          '& .chakra-popover__content': {
            zIndex: 1500
          }
        }}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
      >
        <Flex align="center" height="100%" p="8px">
          <Box
            {...listeners}
            cursor="grab"
            mr="4px"
            mb="3px"
            p="0px"
            borderRadius="4px"
            _hover={{ bg: "gray.100" }}
            opacity={isHovered ? 1 : 0}
            transition="opacity 0.2s"
          >
            <Icon as={DragHandleIcon} boxSize={3} color="gray.400" />
          </Box>
          <Flex flex="1" justify="space-between" align="center">
            {React.Children.map(children, child => {
              if (React.isValidElement(child) && child.type === Popover) {
                return React.cloneElement(child as React.ReactElement<any>, {
                  children: React.Children.map(child.props.children, popoverChild => {
                    if (React.isValidElement(popoverChild) && popoverChild.type === PopoverTrigger) {
                      return React.cloneElement(popoverChild as React.ReactElement<any>, {}, 
                        'children' in (popoverChild.props as any) && React.isValidElement((popoverChild.props as any).children)
                          ? React.cloneElement((popoverChild.props as any).children as React.ReactElement<any>, {
                              style: {
                                opacity: isHovered ? 1 : 0,
                                transition: "opacity 0.2s",
                              }
                            })
                          : (popoverChild.props as any).children
                      );
                    }
                    return popoverChild;
                  })
                });
              }
              return child;
            })}
          </Flex>
        </Flex>
        <Box
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
          position="absolute"
          right={0}
          top={0}
          height="100%"
          width="5px"
          cursor="col-resize"
          userSelect="none"
          //zIndex={1}
          sx={{
            touchAction: "none"
          }}
          _hover={{
            backgroundColor: "gray.200"
          }}
        />
        {over?.id === header.id && (
          <Box
            position="absolute"
            left={0}
            top={0}
            bottom={0}
            width="4px"
            bg="orange.500"
          />
        )}
      </Th>
    );
  };
  

  export default SortableHeader;