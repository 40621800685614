import React, { useState } from 'react';
import {
  Button,
  Select,
  VStack,
  Text,
  Flex,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';

interface CalculationEditorProps {
  field: any;
  allFields: any[];
  updateFieldOptions: (side: 'left' | 'center' | 'right', fieldId: string, newOptions: any) => void;
  side: 'left' | 'center' | 'right';
  onClose: () => void;
}

// Add this function before the CalculationEditor component
const formatFormulaWithLabels = (formula: string, allFields: any[]): string => {
  return formula.replace(/\{([^}]+)\}/g, (match, fieldId) => {
    const field = allFields.find(f => f.id === fieldId);
    return field ? `{${field.label}}` : match;
  });
};

const CalculationEditor: React.FC<CalculationEditorProps> = ({ field, allFields, updateFieldOptions, side, onClose }) => {
  const [formula, setFormula] = useState(field.options.formula || '');
  const [error, setError] = useState<string | null>(null);

  const numericFields = allFields.filter(f => f.id !== field.id);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgColor = useColorModeValue('white', 'navy.700');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

  const handleFieldSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue !== 'default') {
      const selectedField = numericFields.find(f => f.id === selectedValue);
      if (selectedField) {
        setFormula((prev: string) => (prev.trim() + ` {${selectedField.label}}`).trim());
      }
      e.target.value = 'default'; // Reset to default option
    }
  };

  const handleOperatorSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue !== 'default') {
      setFormula((prev: string) => (prev.trim() + ` ${selectedValue} `).trim());
      e.target.value = 'default'; // Reset to default option
    }
  };

  const handleFormulaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const trimmedValue = e.target.value.trim();
    setFormula(trimmedValue);
    // Clear any existing error when the user modifies the formula
    setError(null);
  };

  const validateFormula = (value: string): boolean => {
    const regex = /\{([^}]+)\}|(\d+(\.\d+)?)|(\+|\-|\*|\/)/g;
    const tokens = value.match(regex) || [];
    
    if (tokens.length < 3) {
      return false;
    }

    for (let i = 0; i < tokens.length; i++) {
      if (i % 2 === 0) {
        // Should be a field or a number
        if (!tokens[i].startsWith('{') && !tokens[i].endsWith('}') && isNaN(Number(tokens[i]))) {
          return false;
        }
      } else {
        // Should be an operator
        if (!['+', '-', '*', '/'].includes(tokens[i])) {
          return false;
        }
      }
    }

    if (tokens.length % 2 === 0) {
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (validateFormula(formula)) {
      const regex = /\{([^}]+)\}|(\d+(\.\d+)?)|(\+|\-|\*|\/)/g;
      const tokens = formula.match(regex) || [];
      const fields = tokens
        .filter((token: string) => token.startsWith('{') && token.endsWith('}'))
        .map((token: string) => {
          const label = token.slice(1, -1).trim();
          const field = allFields.find(f => f.label === label);
          return field ? field.id : label;
        });
      const operators = tokens.filter((token: string) => ['+', '-', '*', '/'].includes(token));
      const constants = tokens.filter((token: string) => !isNaN(Number(token))).map(Number);
      
      const sanitizedFormula = formula.replace(/\{([^}]+)\}/g, (match:string, p1:string) => {
        const field = allFields.find(f => f.label === p1.trim());
        return field ? `{${field.id}}` : match;
      });
      
      console.log('Saving calculation formula:', sanitizedFormula);
      console.log('Fields used in calculation:', fields);
      
      updateFieldOptions(side, field.id, { 
        formula: sanitizedFormula, 
        fields, 
        operators,
        constants,
        formatAsInteger: !constants.some((c: number) => !Number.isInteger(c))
      });
      onClose();
    } else {
      setError('Formula must contain at least two operands and one operator');
    }
  };
  
  const handleClear = () => {
    setFormula('');
    setError(null);
  };

  // When displaying the formula
  const displayFormula = formatFormulaWithLabels(formula, allFields);

  return (
      <VStack spacing={4} align="stretch">
        <Textarea
          value={displayFormula.trim()}
          onChange={handleFormulaChange}
          placeholder="Enter your formula here"
          size="sm"
          borderColor={borderColor}
          _hover={{ borderColor: 'brand.500' }}
          _focus={{ borderColor: 'brand.500', boxShadow: '0 0 0 1px brand.500' }}
          borderRadius="16px"
          minH="100px"
        />
        {error && <Text color="red.500" fontSize="sm">{error}</Text>}
        <Flex justifyContent="space-between">
          <Select
            onChange={handleFieldSelect}
            defaultValue="default"
            w="48%"
            borderColor={borderColor}
            _hover={{ borderColor: 'brand.500' }}
            _focus={{ borderColor: 'brand.500', boxShadow: '0 0 0 1px brand.500' }}
            borderRadius="16px"
          >
            <option value="default" disabled>Select a field</option>
            {numericFields.map(f => (
              <option key={f.id} value={f.id}>{f.label}</option>
            ))}
          </Select>
          <Select
            onChange={handleOperatorSelect}
            defaultValue="default"
            w="48%"
            borderColor={borderColor}
            _hover={{ borderColor: 'brand.500' }}
            _focus={{ borderColor: 'brand.500', boxShadow: '0 0 0 1px brand.500' }}
            borderRadius="16px"
          >
            <option value="default" disabled>Select an operator</option>
            <option value="+">+</option>
            <option value="-">-</option>
            <option value="*">*</option>
            <option value="/">/</option>
          </Select>
        </Flex>
        <Flex justifyContent="flex-end" mt={2}>
          <Button onClick={handleClear} mr={2} variant="outline" borderRadius="16px">
            Clear
          </Button>
          <Button onClick={onClose} mr={2} variant="outline" borderRadius="16px">
            Cancel
          </Button>
          <Button onClick={handleSave} colorScheme="brand" borderRadius="16px">
            Save
          </Button>
        </Flex>
      </VStack>
  );
};

export default CalculationEditor;