import React from 'react';
import { Flex, Text, Box, Image, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

interface CompanyInfoCardProps {
  companyData: {
    name: string;
    logo?: string;
    userLimit: number;
    userCount: number;
    diskSpaceQuota?: number;
    usedSpace: number;
    package: string;
  };
  height?: string;
}

const CompanyInfoCard: React.FC<CompanyInfoCardProps> = ({ companyData, height }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const logoSrc = companyData.logo && companyData.logo.startsWith('http') 
    ? companyData.logo 
    : `${process.env.REACT_APP_API_URL}${companyData.logo}`;

  const diskSpaceQuota = companyData.diskSpaceQuota || 0;
  return (
    <Card height={height} mb="20px" p={6}>
      <Flex direction="column" alignItems="center">
        <Box width="150px" height="150px" mb={4}>
          {companyData.logo ? (
            <Image 
              src={logoSrc}
              alt="Company Logo" 
              objectFit="contain"
              width="100%" 
              height="100%" 
              borderRadius="md"
              crossOrigin="anonymous"
              p={2}
              fallback={
                <Box 
                  width="100%" 
                  height="100%" 
                  bg="gray.100" 
                  display="flex" 
                  alignItems="center" 
                  justifyContent="center"
                  borderRadius="md"
                >
                  <Text color="gray.500" fontSize="sm">Logo</Text>
                </Box>
              }
            />
          ) : (
            <Box 
              width="100%" 
              height="100%" 
              bg="gray.100" 
              display="flex" 
              alignItems="center" 
              justifyContent="center"
              borderRadius="md"
            >
              <Text color="gray.500" fontSize="sm">No Logo Available</Text>
            </Box>
          )}
        </Box>
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          {companyData.name}
        </Text>
        <Text fontSize="md" color={textColorSecondary}>
          <Text as="span" fontWeight="bold">Package:</Text> {companyData.package}
        </Text>
        <Text fontSize="md" color={textColorSecondary}>
        <Text as="span" fontWeight="bold">User Limit:</Text> {companyData.userCount}/{companyData.userLimit}
        </Text>
        <Text fontSize="md" color={textColorSecondary}>
        <Text as="span" fontWeight="bold">Disk Space Quota:</Text> {(companyData.usedSpace / (1024 * 1024)).toFixed(2)}/{diskSpaceQuota} MB
        </Text>
      </Flex>
    </Card>
  );
};

export default CompanyInfoCard;