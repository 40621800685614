import { Icon } from "@chakra-ui/react";
import { IconProps } from '@chakra-ui/react';


interface SVGIconProps extends IconProps {

    viewBox?: string;
    color?: string;
}

const TableIcon: React.FC<SVGIconProps> = ({ viewBox = '0 0 24 24', color = 'currentColor', ...props }) => {
    return (
        <Icon viewBox={viewBox} color={color}  style={{marginTop: "-8px"}} {...props}  >
            <svg width="100%" height="100%"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.88379 5.14619C3.88379 4.6688 4.07343 4.21096 4.411 3.8734C4.74856 3.53583 5.2064 3.34619 5.68379 3.34619H8.68379V8.14619H3.88379V5.14619ZM3.88379 9.34619V19.5462C3.88379 20.0236 4.07343 20.4814 4.411 20.819C4.74856 21.1566 5.2064 21.3462 5.68379 21.3462H8.68379V9.34619H3.88379ZM9.88379 21.3462H20.0838C20.5612 21.3462 21.019 21.1566 21.3566 20.819C21.6941 20.4814 21.8838 20.0236 21.8838 19.5462V9.34619H9.88379V21.3462ZM21.8838 8.14619V5.14619C21.8838 4.6688 21.6941 4.21096 21.3566 3.8734C21.019 3.53583 20.5612 3.34619 20.0838 3.34619H9.88379V8.14619H21.8838Z" fill="#FE7053"/>
            </svg>

        </Icon>
    );
};

export default TableIcon;