import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Portal,
} from '@chakra-ui/react';

interface CenteredPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const CenteredPopover: React.FC<CenteredPopoverProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <Portal>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        isCentered
        closeOnOverlayClick={true}
        closeOnEsc={true}
        motionPreset="scale"
        blockScrollOnMount={false}
        onCloseComplete={() => {
          // Force cleanup of any lingering elements
          const overlays = document.querySelectorAll('.chakra-modal__overlay, .chakra-portal, [role="dialog"]');
          overlays.forEach(overlay => {
            if (overlay.parentNode) {
              overlay.parentNode.removeChild(overlay);
            }
          });
        }}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.5)" />
        <ModalContent
          width="400px"
          borderRadius="16px"
          boxShadow="0px 3px 27px -20px rgba(112, 144, 176, 0.51)"
          position="relative"
          zIndex="modal"
        >
          <ModalHeader fontWeight="normal" borderBottom="0">
            {title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

export default CenteredPopover;