import { createSlice } from '@reduxjs/toolkit'

const zoneSlice = createSlice({
  name: 'zones',
  initialState: {
    allZones: [],
    activeZone: "",
  },
  reducers: {
    addZones(state, action) {
     if(Array.isArray(action.payload)){
      const newZones = action.payload.filter(
        (newZone: any) => !state.allZones.some((zone) => zone.id === newZone.id)
    );
      state.allZones = [...state.allZones, ...newZones];
     } else {
      state.allZones = [...state.allZones,action.payload];
     }
  
    
    },
    renameZone(state, action) {
      const { id, newName } = action.payload;
      const zoneToUpdate = state.allZones.find(zone => zone._id === id);
      if (zoneToUpdate) {
        zoneToUpdate.name = newName;
      }
    },
    deleteZone(state, action) {
      state.allZones = state.allZones.filter(zone => zone._id !== action.payload);
    },
    resetZones(state){
      state.allZones = [];
    },
    setActiveZone(state, action){
      if (state) {
        state.activeZone = action.payload;
      }
    }
  },
})

export const { addZones, renameZone, deleteZone , resetZones, setActiveZone } = zoneSlice.actions
export default zoneSlice.reducer