import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Table,
  Thead,
  Flex,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
  Spinner,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Icon,
  Checkbox,
  Tooltip
} from '@chakra-ui/react';
import { AddIcon, EditIcon, CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons';
import Card from 'components/card/Card';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { zoneService } from 'services/zoneService';
import ZonePage from './ZonePage';
import { css } from '@emotion/react';
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { MdImage, MdPictureAsPdf, MdInsertDriveFile } from 'react-icons/md';
import { SiMicrosoftword, SiMicrosoftexcel } from 'react-icons/si';
import { FaFileExcel } from 'react-icons/fa';
import { exportToExcel } from '../../../../services/exportService';
import { useDispatch } from 'react-redux';
import { setActiveZone } from 'store/slices/zoneSlice';

interface ZoneItem {
  id: string;
  [key: string]: any;
}

interface Field {
  id: string;
  _id: string;
  type: string;
  label: string;
  name: string;
  appearInTable: boolean;
  options?: { label: string; color: string; }[];
  required: boolean;
  tabId: string;
  order: number;
  position: string;
}

interface Zone {
  _id: string;
  name: string;
  type: string;
  availableUsers: string[];
  tabs: any[];
  columns: string[];
  columnOrder: string[];
  columnTypes: { [key: string]: string };
  columnWidths: { [key: string]: number };
  data: any[];
  selectOptions: { [key: string]: string[] };
  fields: {
    id: string; name: string, _id: string, appearInTable: boolean,
    label: string;
  }[];
}

interface ZoneListPageProps {
  setCurrentZoneName?: (name: string) => void;
  type?: string,
  zone?: Zone,
  reportResult?: any
  reportZoneId?: string | undefined
  noPadding?: boolean
}

const freezeHeaderStyle = css`
  position: sticky;
  top: 0;
  zIndex: 10;
  background-color: var(--chakra-colors-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ZoneListPage: React.FC<ZoneListPageProps> = ({ 
  setCurrentZoneName = (name: string) => { }, 
  type = "", 
  zone = undefined, 
  reportResult = undefined, 
  reportZoneId = undefined,
  noPadding = false
}) => {
  const [zoneItems, setZoneItems] = useState<ZoneItem[]>([]);
  const [zoneFields, setZoneFields] = useState<Field[]>([]);
  const [zoneFieldsLabel, setZoneFieldsLabel] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true);
  const [zoneType, setZoneType] = useState<string>("");
  const { id: zoneId } = useParams<{ id: string }>();
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dispatch = useDispatch();

  const filteredZoneItems = useMemo(() => {
    return zoneItems.filter((item) =>
      Object.values(item.data || {}).some((field) =>
        String((field as any).value ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [zoneItems, searchTerm]);

  useEffect(() => {
    // if (zoneId && !type) {
      fetchZoneData(reportZoneId ? reportZoneId : zoneId);

      if (reportResult) {
        setZoneItems(reportResult)
      }
  }, [zoneId, reportResult]);

  const handleRowSelection = (itemId: string) => {
    setRowSelection(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const exportSelectedItems = async () => {
    const selectedItems = zoneItems.filter(item => rowSelection[item._id]);
    if (selectedItems.length > 0) {
      try {
        const zoneFields = await zoneService.getZoneFields(zoneId);
        const fullItemData = await Promise.all(selectedItems.map(async item => {
          try {
            return await zoneService.getZoneItemById(zoneId, item._id, true);
          } catch (error) {
            console.error(`Error fetching item ${item._id}:`, error);
            return null;
          }
        }));

        const validItemData = fullItemData.filter(item => item !== null);

        if (validItemData.length === 0) {
          throw new Error("No valid items to export");
        }

        const exportData = validItemData.map(item => {
          const rowData: { [key: string]: any } = {};
          zoneFields.forEach((field: { id: string; label: string }) => {
            const fieldValue = item.data[field.id];
            rowData[field.label] = fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue
              ? fieldValue.value
              : fieldValue;
          });
          return rowData;
        });

        const fileName = `${zoneType}_export_${new Date().toISOString()}.xlsx`;
        exportToExcel(exportData, fileName);
        toast({
          title: "Export Successful",
          description: `${validItemData.length} items exported to ${fileName}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error exporting items:", error);
        toast({
          title: "Export Failed",
          description: "An error occurred while exporting items",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Export Failed",
        description: "No items selected for export",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchZoneData = async (currZoneId: string) => {
    setIsLoading(true);
    try {
      const zoneData = await zoneService.getZoneById(currZoneId);
      // setCurrentZoneName(zoneData.data.name);
      dispatch(setActiveZone(zoneData.data.name));

      if (zoneData && zoneData?.data) {
        const tableFields = zoneData?.data?.fields?.filter((field: Field) => field?.appearInTable)
        if (tableFields) {
          setZoneFieldsLabel(tableFields?.map((field: Field) => field?.id));
          // Ensure we're getting the options for label fields
          const fieldsWithOptions = tableFields.map((field: Field) => {
            if (field.type === 'Label' && zoneData.data.selectOptions && zoneData.data.selectOptions[field.id]) {
              return {
                ...field,
                options: zoneData.data.selectOptions[field.id].map((option: string) => ({
                  label: option,
                  color: zoneData.data.labelColors?.[field.id]?.[option] || 'gray.300'
                }))
              };
            }
            return field;
          });
          setZoneFields(fieldsWithOptions);
        }
        setZoneType(zoneData.data.type)
      }
      if (!type) {
        const items = await Promise.all(
          (await zoneService.getZoneItems(currZoneId)).map(async (item: any) => {
            return await zoneService.getZoneItemById(currZoneId, item._id, false);
          })
        );
        setZoneItems(items);
      }
    } catch (error: unknown) {
      console.error('Error fetching zone data:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      }
      if (typeof error === 'object' && error !== null) {
        const axiosError = error as { response?: { data?: any; status?: number }; request?: any };
        if ('response' in axiosError && axiosError.response) {
          console.error('Response data:', axiosError.response.data);
          console.error('Response status:', axiosError.response.status);
        } else if ('request' in axiosError) {
          console.error('No response received:', axiosError.request);
        }
      }
      // Optionally, you can set an error state here to display to the user
      // setError('Failed to fetch zone data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateItem = () => {
    navigate(`/admin/zones/${zoneId}/item/new`);
  };

  const handleEditItem = (itemId: string) => {
    navigate(`/admin/zones/${zoneId}/item/${itemId}/edit`);
  };

  const handleDuplicateItem = async (itemId: string) => {
    try {
      const duplicatedItem = await zoneService.duplicateZoneItem(zoneId, itemId);
      setZoneItems(prevItems => {
        const originalItemIndex = prevItems.findIndex(item => item._id === itemId);
        const newItems = [...prevItems];
        newItems.splice(originalItemIndex + 1, 0, duplicatedItem);
        return newItems;
      });
      toast({
        title: "Item duplicated",
        description: "The item has been successfully duplicated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error duplicating item:', error);
      toast({
        title: "Error",
        description: "Failed to duplicate the item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItem = (itemId: string) => {
    setItemToDelete(itemId);
    onOpen();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const handleDeleteConfirm = async () => {
    try {
      await zoneService.deleteZoneItem(zoneId, itemToDelete);
      setZoneItems(prevItems => prevItems.filter(item => item._id !== itemToDelete));
      toast({
        title: "Item deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error deleting item:', error);
      toast({
        title: "Error deleting item",
        description: "Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
  };

  const getFileIcon = (fileName: any) => {
    if (typeof fileName !== 'string') {
      //console.warn('Invalid fileName:', fileName);
      return MdInsertDriveFile;
    }
    const parts = fileName.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return MdImage;
      case 'pdf':
        return MdPictureAsPdf;
      case 'doc':
      case 'docx':
        return SiMicrosoftword;
      case 'xls':
      case 'xlsx':
        return SiMicrosoftexcel;
      default:
        return MdInsertDriveFile;
    }
  };

  if (zoneType === "table") {
    return <ZonePage setCurrentZoneName={setCurrentZoneName} isEditable={false} reportResult={reportResult}/>
  }

  return (
    <Box pt={{ base: type ? '10px' : '130px', md: type ? '10px' : '80px', xl: type ? '10px' : '80px' }} height="calc(100vh - 50px)">
      <Card 
        flexGrow={1} 
        display="flex" 
        flexDirection="column" 
        overflow="hidden" 
        height="100%"
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Flex alignItems="center" gap={4}>
            <SearchBar
              placeholder="Search..."
              value={searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
              width="300px"
            />
            <Text color="gray.600" fontSize="sm">
              {filteredZoneItems.length} {filteredZoneItems.length === 1 ? 'result' : 'results'} for this zone
            </Text>
            {Object.values(rowSelection).some(Boolean) && (
              <>
                <Text
                  fontSize="18px"
                  color="brand.500"
                  fontWeight="normal"
                  ml="100px"
                >
                  {Object.values(rowSelection).filter(Boolean).length} row{Object.values(rowSelection).filter(Boolean).length === 1 ? '' : 's'} selected
                </Text>
                <Button
                  colorScheme="brand"
                  variant="outline"
                  leftIcon={<Icon as={FaFileExcel} />}
                  onClick={exportSelectedItems}
                  fontSize="14px"
                  fontWeight="normal"
                >
                  Export to Excel
                </Button>
              </>
            )}
          </Flex>
          
          {!type && (
            <Button 
              colorScheme="brand" 
              leftIcon={<AddIcon />}
              onClick={handleCreateItem}
              fontSize="14px"
              size="sm"
              fontWeight="normal"
            >
              Create New Item
            </Button>
          )}
        </Flex>
        {isLoading ? (
          <Spinner />
        ) : zoneFields?.length === 0 || zoneItems?.length === 0 ? (
          <Text>No data available. Please check your zone configuration.</Text>
        ) : (
          <Box flexGrow={1} overflow="auto">
            <Box>
              <Table variant="simple" style={{ minWidth: `${(zoneFields.length + 2) * 100}px` }} className='zoneListTable'>
                <Thead css={[freezeHeaderStyle, { position: 'sticky', top: 0 }]}>
                  <Tr>
                    <Th
                      width="40px"
                      color="gray.700"
                      fontWeight="normal"
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                      padding="3px 12px"
                      textAlign="center"
                    >
                      <Flex justify="center" align="center" height="100%">
                        <Checkbox
                          isChecked={Object.keys(rowSelection).length > 0 && Object.keys(rowSelection).every(key => rowSelection[key])}
                          isIndeterminate={Object.keys(rowSelection).some(key => rowSelection[key]) && !Object.keys(rowSelection).every(key => rowSelection[key])}
                          colorScheme="orange"
                          onChange={() => {
                            const allSelected = Object.keys(rowSelection).every(key => rowSelection[key]);
                            setRowSelection(
                              filteredZoneItems.reduce((acc, item) => ({
                                ...acc,
                                [item._id]: !allSelected
                              }), {})
                            );
                          }}
                        />
                      </Flex>
                    </Th>
                    {zoneFields.map((field) => (
                      <Th
                        key={field._id || field.label + Math.random() * 9999}
                        maxWidth="130px"
                        color="gray.700"
                        fontWeight="normal"
                        borderWidth="1px"
                        borderColor={borderColor}
                        borderStyle="solid"
                        padding="3px 6px"
                      >
                        <Tooltip
                          bg="gray.800"
                          label={field.label}
                          isDisabled={field.label.length <= 20}
                          placement="top"
                          hasArrow
                        >
                          <Flex
                            justify="space-between"
                            align="left"
                            color="gray.700"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {field.label}
                          </Flex>
                        </Tooltip>
                      </Th>
                    ))}
                    {!type && <Th 
                      maxWidth="80px"
                      color="gray.700"
                      fontWeight="normal"
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                     padding="3px 6px">Actions</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredZoneItems.map((item) => (
                    <Tr 
                      key={item._id}
                      bg={rowSelection[item._id] ? '#fff4f2' : 'transparent'}
                      transition="background-color 0.2s"
                    >
                      <Td
                        width="40px"
                        borderColor={borderColor}
                        borderWidth="1px"
                        borderStyle="solid"
                       padding="3px 6px"
                        textAlign="center"
                      >
                        <Flex justify="center" align="center" height="100%">
                          <Checkbox
                            isChecked={rowSelection[item._id] || false}
                            onChange={() => handleRowSelection(item._id)}
                            colorScheme="orange"
                          />
                        </Flex>
                      </Td>
                      {zoneFieldsLabel.map((field, idx) => {
                        const fieldData = zoneFields.find(f => f.id === field);
                        const fieldType = fieldData?.type;
                        const fieldOptions = fieldData?.options;
                        const cellContent = item.data && item.data[field] ? 
                          Array.isArray(item.data[field].value) 
                            ? item.data[field].value.join(', ')
                            : item.data[field].value 
                          : '';
                        
                        return (
                          <Td
                            key={`${field}-${item.id}-${idx}`}
                            minWidth="100px"
                            maxWidth="130px"
                            borderColor={borderColor}
                            borderWidth="1px"
                            borderStyle="solid"
                            padding="3px 6px"
                            height="39px"
                            maxHeight="39px"
                            sx={{
                              '& > *': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }
                            }}
                          >
                            <Tooltip
                              bg="gray.800"
                              label={cellContent}
                              isDisabled={!cellContent || cellContent.length <= 20}
                              placement="top"
                              hasArrow
                            >
                              <Box>
                                {fieldType === 'File' && item.data && item.data[field] ? (
                                  <Link 
                                    to={item.data[field].value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Icon 
                                      as={getFileIcon(item.data[field].value)}
                                      boxSize={6}
                                    />
                                  </Link>
                                ) : fieldType === 'Label' && item.data && item.data[field] ? (
                                  <Flex alignItems="center" width="100%">
                                    <Tooltip
                                      bg="gray.800"
                                      label={`${item.data[field].value}`}
                                      placement="top"
                                      hasArrow
                                    >
                                      <Box 
                                        flexShrink={0}
                                        width="20px" 
                                        height="20px" 
                                        borderRadius="50%" 
                                        backgroundColor={fieldOptions?.find(opt => opt.label === item.data[field].value)?.color || 'gray.300'} 
                                        marginRight="10px"
                                        cursor="pointer"
                                      />
                                    </Tooltip>
                                    <Box overflow="hidden">
                                      <Tooltip
                                        bg="gray.800"
                                        label={item.data[field].value}
                                        isDisabled={!item.data[field].value || item.data[field].value.length <= 15}
                                        placement="top"
                                        hasArrow
                                      >
                                        <Text
                                          whiteSpace="nowrap"
                                          overflow="hidden"
                                          textOverflow="ellipsis"
                                        >
                                          {item.data[field].value}
                                        </Text>
                                      </Tooltip>
                                    </Box>
                                  </Flex>
                                ) : idx === 0 ? (
                                  <Link to={`/admin/zones/${zoneId}/item/${item._id}/edit`}>
                                    {cellContent}
                                  </Link>
                                ) : (
                                  cellContent
                                )}
                              </Box>
                            </Tooltip>
                          </Td>
                        );
                      })}
                      {!type && (
                        <Td 
                          minWidth="80px"
                          borderColor={borderColor}
                          borderWidth="1px"
                          borderStyle="solid"
                          padding="3px"
                        >
                          <Menu>
                            <MenuButton
                               as={IconButton}
                               aria-label='Options'
                               icon={<MenuDots boxSize={6} />}
                               variant='ghost'
                               size='sm'
                               _hover={{
                                 bg: "gray.200"
                               }} 
                            />
                            <MenuList className='smallMenuDrop'>
                              <MenuItem 
                                icon={<Icon as={EditIcon} color="orange.500" />}
                                onClick={() => handleEditItem(item._id)}>
                                Edit
                              </MenuItem>
                              <MenuItem 
                              icon={<Icon as={CopyIcon} color="orange.500" />}
                              onClick={() => handleDuplicateItem(item._id)}>
                                Duplicate
                              </MenuItem>
                              <MenuItem 
                               icon={<Icon as={DeleteIcon} color="orange.500" />}
                               onClick={() => handleDeleteItem(item._id)}>
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Card>
      {isOpen && 
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Item</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this item?
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="solid" colorScheme="red" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>}
    </Box>
  );
};

export default ZoneListPage;
