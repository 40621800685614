import React, { useState, useEffect } from 'react';
import SuperAdminRoute from '../../../../components/SuperAdminRoute';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  Card
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

interface Company {
  _id: string;
  name: string;
  package: string;
  userCount: number;
  diskSpaceQuota: number;
}

const CompaniesPage: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const toast = useToast();

  const fetchCompanies = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/api/superadmin/companies`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response && response.data) {
        
        setCompanies(response.data);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error fetching companies:', error.response?.data || error.message);
        let errorMessage = 'An unexpected error occurred';
        if (error.response?.data?.error) {
          // ... rest of the error handling
        }
      } else {
        console.error('Error fetching companies:', error);
      }
      // ... rest of the error handling
    }

  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleDeleteCompany = async (companyId: string) => {
    try {
      await axios.delete(`/api/superadmin/companies/${companyId}`);
      fetchCompanies();
      toast({
        title: 'Company deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting company:', error);
      toast({
        title: 'Error deleting company',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChangePackage = async (companyId: string, newPackage: string) => {
    try {
      await axios.put(`/api/superadmin/companies/${companyId}/package`, { package: newPackage });
      fetchCompanies();
      toast({
        title: 'Package updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error changing package:', error);
      toast({
        title: 'Error changing package',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLoginAsAdmin = async (companyId: string) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/superadmin/companies/${companyId}/login-as-admin`);
      const { token } = response.data;
      localStorage.setItem('token', token);
      window.location.href = '/admin/dashboard';
    } catch (error) {
      console.error('Error logging in as admin:', error);
      toast({
        title: 'Error logging in as admin',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card>
        <Flex direction="column" p={6}>
          <Table variant="simple" className='dibiTable'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Package Type</Th>
                <Th>Active Users</Th>
                <Th>File Quota</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {companies.map((company) => (
                <Tr key={company._id}>
                  <Td>{company.name}</Td>
                  <Td>{company.package}</Td>
                  <Td>{company.userCount}</Td>
                  <Td>{company.diskSpaceQuota} MB</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<FiMoreVertical />}
                        variant="outline"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleDeleteCompany(company._id)}>
                          Delete Company
                        </MenuItem>
                        <MenuItem onClick={() => handleChangePackage(company._id, 'newPackage')}>
                          Change Package
                        </MenuItem>
                        <MenuItem onClick={() => handleLoginAsAdmin(company._id)}>
                          Login as Company Admin
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Card>
    </Box>
  );
};

export default function ProtectedCompaniesPage() {
  return (
    <SuperAdminRoute>
      <CompaniesPage />
    </SuperAdminRoute>
  );
}