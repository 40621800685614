import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import { MenuDots } from 'components/icons/Icons';
import Card from 'components/card/Card';
import {  Link, useNavigate, useLocation } from 'react-router-dom';
import { zoneService } from 'services/zoneService';
import TabContentSection from '../../../../components/TabContentSection';
import { useAuth } from 'contexts/AuthContext';
import { Field } from '../../../../types/Field';
import FileField from '../../../../components/FileField';

interface TabData {
  id: string;
  name: string;
  content: string;
  isEditing?: boolean;
}

interface ZoneTabPageProps {
  setCurrentZoneName: (name: string) => void;
  zoneId: string;
  isEditable?: boolean,
  itemId: string
}

const ZoneTabPage: React.FC<ZoneTabPageProps> = ({ setCurrentZoneName, zoneId, isEditable = true, itemId = "" }) => {
  const [zoneName, setZoneName] = useState<string>("");
  const [tabData, setTabData] = useState<any>({});
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [tabs, setTabs] = useState<TabData[]>([]); // Changed to an empty array
  const [tabToDelete, setTabToDelete] = useState<string | number | null>(null); // Changed to string | number | null
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const cancelRef = useRef<HTMLButtonElement>(null);
  const [currentTabId, setCurrentTabId] = useState<string>('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const companyId = useAuth().user.companyId;
  const [zoneFields, setZoneFields] = useState<Field[]>([]);
  const [isNewItem, setIsNewItem] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const validateRequiredFields = () => {
    const errors: string[] = [];
    zoneFields.forEach((field) => {
      if (field.required && (!tabData[field.id] || !tabData[field.id].value)) {
        errors.push(`${field.label} is required`);
      }
    });
    return errors;
  };

  
  const handleTabFields = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('handleTabFields called with:', e.target.name, e.target.value);
    setTabData((prv: any) => {
      const newData = { ...prv, [e.target.name]: { ...prv[e.target.name], key: e.target.name, value: e.target.value } };
      return newData;
    });
    setHasChanges(true);
  };

  const saveItemsData = async () => {
    const errors = validateRequiredFields();
    if (errors.length > 0) {
      toast({
        title: "Validation Error",
        description: errors.join(", "),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    try {
      let response;
      if (isNewItem) {
        response = await zoneService.createZoneItem(zoneId, tabData, companyId);
      } else {
        response = await zoneService.updateZoneItem(zoneId, itemId, tabData);
      }
  
      if (response?.data) {
        setTabData(response.data);
        setIsNewItem(false);
        setHasChanges(false); // Set to false after successful save
        toast({
          title: "Success",
          description: isNewItem ? "Item created successfully" : "Data updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error saving/updating data:', error);
      toast({
        title: "Error",
        description: `Failed to ${isNewItem ? 'create' : 'update'} data. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const openDeleteModal = (index: number) => {
    setTabToDelete(index);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const addNewTab = async () => {
    const newTabId = Date.now().toString();
    const newTab = { id: newTabId, name: `New Tab ${tabs.length + 1}`, content: '' };
    const newTabs = [...tabs, newTab];
    setTabs(newTabs);
    try {
      await zoneService.saveTabData(zoneId, newTabs);
    } catch (error) {
      setTabs(tabs);
    }
  };

  const handleTabAction = async (index: number, action: 'rename' | 'duplicate' | 'delete') => {
    switch (action) {
      case 'rename':
        setTabs(tabs.map((tab, i) =>
          i === index ? { ...tab, isEditing: true } : tab
        ));
        break;
      case 'duplicate':
        const newTab = { ...tabs[index], id: Date.now().toString(), name: `${tabs[index].name} (Copy)` };
        const newTabs = [...tabs.slice(0, index + 1), newTab, ...tabs.slice(index + 1)];
        setTabs(newTabs);
        try {
          await zoneService.saveTabData(zoneId, newTabs);
          //.log('Tab duplicated and saved successfully');
        } catch (error) {
          // console.error('Error saving duplicated tab:', error);
          // Optionally, revert the state if saving fails
          setTabs(tabs);
        }
        break;
      case 'delete':
        openDeleteModal(index);
        break;
    }
  };

  const handleTabNameChange = async (index: number, newName: string) => {
    setTabs(prevTabs =>
      prevTabs.map((tab, i) =>
        i === index ? { ...tab, name: newName, isEditing: false } : tab
      )
    );

    try {
      await zoneService.saveTabData(zoneId, tabs.map((tab, i) =>
        i === index ? { ...tab, name: newName } : tab
      ));
    } catch (error) {
      console.error('Error saving tab name change:', error);
      // Optionally, revert the state if saving fails
      setTabs(prevTabs =>
        prevTabs.map((tab, i) =>
          i === index ? { ...tab, name: tabs[index].name } : tab
        )
      );
    }
  };

  const confirmDelete = async () => {
    if (tabToDelete !== null) {
      const newTabs = tabs.filter((_, index) => index !== tabToDelete);
      try {
        await zoneService.saveTabData(zoneId, newTabs);
        setTabs(newTabs);
        setTabToDelete(null);
      } catch (error) {
      } finally {
        closeDeleteModal();
      }
    }
  };

  const fetchZoneItem = async () => {
    if (itemId) {
      try {
        const response = await zoneService.getZoneItem(itemId);
        if (response?.data) {
          const initialData = { ...response.data };
          zoneFields.forEach(field => {
            if (field.type === 'Calculation') {
              if (!initialData[field.id]) {
                initialData[field.id] = { value: '0', key: field.id };
              }
            }
          });
          setTabData(initialData);
          setIsNewItem(false);
          setHasChanges(false);
        }
      } catch (error) {
        console.error('Error fetching zone item:', error);
      }
    } else {
      setIsNewItem(true);
      setTabData({});
      setHasChanges(false);
    }
  };

  const updateDataItem = async () => {
    const errors = validateRequiredFields();
    if (errors.length > 0) {
      // Highlight empty required fields in red
      setZoneFields(prevFields => prevFields.map(field => ({
        ...field,
        isInvalid: field.required && (!tabData[field.id] || !tabData[field.id].value)
      })));

      // Show toast alert
      toast({
        title: "Validation Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await zoneService.updateZoneItem(zoneId, itemId, tabData);

      if (response?.data) {
        setTabData(response.data);
        toast({
          title: "Success",
          description: "Data updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating data:', error);
      toast({
        title: "Error",
        description: "Failed to update data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchZoneData = async () => {
      if (!zoneId) {
        return;
      }
      try {
        const response = await zoneService.getZoneById(zoneId);
        const zoneData = response.data;
        if (zoneData.name) {
          setZoneName(zoneData.name);
          setCurrentZoneName(zoneData.name);
        }
        if (zoneData.tabs && Array.isArray(zoneData.tabs)) {
          const tabsWithUniqueIds = zoneData.tabs.map((tab: Partial<TabData>) => ({
            ...tab,
            id: tab.id || Date.now().toString(),
            isEditing: false
          }));
          setTabs(tabsWithUniqueIds);
          setSelectedTabIndex(0);
        } else {
          const defaultTab = { id: Date.now().toString(), name: 'Main', content: '', isEditing: false };
          setTabs([defaultTab]);
          await zoneService.saveTabData(zoneId, [defaultTab]);
          setSelectedTabIndex(0);
        }
      } catch (error) {
        // console.error('Error fetching zone data:', error);
        setTabs([{ id: 'main', name: 'Main', content: '' }]);
      }
    };

    (async () => {
      await fetchZoneData();
      if (itemId) {
        await fetchZoneItem();
      }
    })()


  }, [zoneId, setCurrentZoneName]);

  useEffect(() => {
    const fetchZoneFields = async () => {
      try {
        const fields = await zoneService.getZoneFields(zoneId);
        setZoneFields(fields);
      } catch (error) {
        console.error('Error fetching zone fields:', error);
      }
    };

    fetchZoneFields();
  }, [zoneId]);

  useEffect(() => {
    const compareData = async () => {
      setHasChanges(false); // Set to false initially
      if (itemId) {
        try {
          const originalData = await zoneService.getZoneItem(itemId);
          if (originalData?.data) {
            const hasChanges = Object.entries(tabData).some(([key, value]) => {
              // Skip comparison for Calculation fields
              if (zoneFields.find(field => field.id === key && field.type === 'Calculation')) {
                return false;
              }
              return JSON.stringify(value) !== JSON.stringify(originalData.data[key]);
            });
            setHasChanges(hasChanges);
          }
        } catch (error) {
          console.error('Error comparing data:', error);
        }
      } else {
        // For new items, check if any field has a value
        const hasAnyValue = Object.entries(tabData).some(([key, field]) => {
          if (typeof field === 'object' && field !== null) {
            // Skip comparison for Calculation fields
            if (zoneFields.find(zoneField => zoneField.id === key && zoneField.type === 'Calculation')) {
              return false;
            }
            return (field as { value?: unknown }).value !== undefined && (field as { value?: unknown }).value !== '';
          }
          return false;
        });
        setHasChanges(hasAnyValue);
      }
    };
    compareData();
  }, [tabData, itemId, zoneFields]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [navigationPath, setNavigationPath] = useState<string | null>(null);

  const handleNavigation = (path: string) => {
    if (hasChanges) {
      setNavigationPath(path);
      onOpen();
    } else {
      navigate(path);
    }
  };

  const handleNavigationConfirm = () => {
    if (navigationPath) {
      saveItemsData().then(() => {
        navigate(navigationPath);
        onClose();
      });
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasChanges]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} height="100vh">
      <Card height="calc(100vh - 100px)" overflow="hidden">
          {isEditable && (
          <Flex justifyContent="flex-start" alignItems="left" mb="6">
          <Box>
            <Text color={textColor} fontSize="2xl" fontWeight="700">
              Form Builder
            </Text>
            <Text color={textColor} fontSize="md">
              Here you can manage the fields and tabs of this zone
            </Text>
          </Box>
        </Flex>
          )}
          {!isEditable && (
            <Flex justifyContent="flex-end">
              <Button 
                colorScheme="gray" 
                size="sm" 
                fontWeight="normal" 
                mr={2} 
                onClick={() => handleNavigation(`/admin/zones/${zoneId}/list?type=tabs`)}
              >
                Back to Zone List
              </Button>
              <Button 
  width="160px" 
  colorScheme="brand" 
  size="sm" 
  fontWeight="normal" 
  onClick={saveItemsData}
  isDisabled={!hasChanges}
>
  Save Data
</Button>
            </Flex>
          )}
        <Tabs index={selectedTabIndex} onChange={(index) => setSelectedTabIndex(index)}>
          <TabList>
            {tabs.map((tab, index) => (
              <Flex key={tab.id} position="relative" alignItems="center" marginRight="15px">
              <Tab>
                <Box>
                  {tab.isEditing ? (
                    <Input
                      size="sm"
                      defaultValue={tab.name}
                      onBlur={(e) => handleTabNameChange(index, e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleTabNameChange(index, e.currentTarget.value);
                        }
                      }}
                      autoFocus
                    />
                  ) : (
                    <Text>{tab.name}</Text>
                  )}
                </Box>
              </Tab>
                {isEditable && (
                  <Box position="absolute" right="-20px">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<MenuDots boxSize={6} />}
                        variant='ghost'
                        size='sm'
                        _hover={{
                          bg: "gray.200"
                        }} 
                      />
                      <MenuList className='smallMenuDrop'>
                        <MenuItem onClick={() => handleTabAction(index, 'rename')}
                          icon={<Icon as={EditIcon} color="orange.500" />}
                          >
                          Rename
                        </MenuItem>
                        <MenuItem onClick={() => handleTabAction(index, 'duplicate')}
                        icon={<Icon as={CopyIcon} color="orange.500" />}
                        >
                          Duplicate
                        </MenuItem>
                        <MenuItem onClick={() => handleTabAction(index, 'delete')}
                        icon={<Icon as={DeleteIcon} color="orange.500" />}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                )}
              </Flex>
            ))}
            {isEditable && (
              <Button
                onClick={addNewTab}
                variant="outline"
                borderWidth={0}
                h="30px"
                borderColor="gray.200"
                color="gray.400"
                fontWeight="400"
                p={0}
                m={0}
                ml={7}
                mt="5px"
                leftIcon={<AddIcon />}
                _hover={{
                  color: 'gray.700'
                }}
              >
                New tab
              </Button>
            )}
          </TabList>
          <TabPanels 
            height={isEditable ? "calc(100vh - 300px)" : "calc(100vh - 240px)"}
            overflowY="auto" 
          >
            {tabs.map((tab, index) => (
              <TabPanel key={tab.id} pt={5} pl={0} pr={0}>
                <TabContentSection
                  zoneId={zoneId}
                  currentTabId={tab.id}
                  setCurrentTabId={setCurrentTabId}
                  isEditable={isEditable}
                  handleTabFields={handleTabFields}
                  itemData={tabData}
                  setItemData={setTabData}
                  allFields={zoneFields.map(field => ({ id: field.id, label: field.label }))}
                  FileField={FileField}
                  toast={toast}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Card>

      <AlertDialog
        isOpen={isDeleteModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Tab
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this tab? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => {
                confirmDelete();
                closeDeleteModal();
              }} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unsaved Changes</ModalHeader>
          <ModalBody>
            You have unsaved changes. Do you want to save before leaving?
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => {
              if (navigationPath) {
                navigate(navigationPath);
                onClose();
              }
            }}>
              Don't Save
            </Button>
            <Button variant="solid" ml={2} colorScheme="orange" onClick={handleNavigationConfirm}>
              Save and Leave
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ZoneTabPage;