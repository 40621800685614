import React from 'react';
import { SimpleGrid, Button } from '@chakra-ui/react';

const predefinedColors = [
  // Yellow to Green (6 colors, light to dark)
  '#FFFF00', '#FFEB3B', '#CDDC39', '#8BC34A', '#4CAF50', '#1B5E20',
  // Orange to Red (6 colors, light to dark)
  '#FFE0B2', '#FF9800', '#FF5722', '#F44336', '#D32F2F', '#B71C1C',
  // Pink to Purple (6 colors, light to dark)
  '#FCE4EC', '#F06292', '#E91E63', '#9C27B0', '#7B1FA2', '#4A148C',
    // Cyan to Blue (6 colors, light to dark)
  '#E0FFFF', '#00BCD4', '#03A9F4', '#2196F3', '#1565C0', '#0D47A1',
  // Gray to Black (6 colors, light to dark)
  '#dbdbdb', '#BDBDBD', '#9E9E9E', '#616161', '#212121', '#000000'
];

interface ColorPaletteProps {
  selectedColor: string;
  onColorSelect: (color: string) => void;
}

const ColorPalette: React.FC<ColorPaletteProps> = ({ selectedColor, onColorSelect }) => {
  return (
    <SimpleGrid columns={6} spacing={2}>
      {predefinedColors.map((color) => (
        <Button
          key={color}
          bg={color}
          w="30px"
          h="30px"
          p={0}
          minW="unset"
          borderRadius="50%"
          onClick={() => onColorSelect(color)}
          border={selectedColor === color ? '2px solid black' : 'none'}
          _hover={{ transform: 'scale(1.1)' }}
        />
      ))}
    </SimpleGrid>
  );
};

export default ColorPalette;