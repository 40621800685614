import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Flex, Divider } from '@chakra-ui/react';
import { zoneService } from 'services/zoneService';
import Card from 'components/card/Card';

interface NumberPanelProps {
  zoneId: string;
  field: string;
}

interface ZoneItem {
  _id: string;
  data: { [key: string]: any };
  createdAt: string;
}

const NumberPanel: React.FC<NumberPanelProps> = ({ zoneId, field }) => {
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [fieldName, setFieldName] = useState<string>('');
  const [zoneName, setZoneName] = useState<string>('');
  const [latestItems, setLatestItems] = useState<ZoneItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const zoneItems = await zoneService.getZoneItems(zoneId);
        const zoneData = await zoneService.getZoneById(zoneId);
        let sum = 0;

        zoneItems.forEach((item: ZoneItem) => {
          const value = parseFloat(item.data[field]?.value || item.data[field] || '0');
          sum += isNaN(value) ? 0 : value;
        });

        setTotal(sum);
        
        const foundField = zoneData.data.fields.find((f: any) => f._id === field || f.id === field);
        setFieldName(foundField ? foundField.name || foundField.label : field);
        setZoneName(zoneData.data.name || 'Unknown Zone');

        const sortedItems = zoneItems.sort((a: ZoneItem, b: ZoneItem) => 
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setLatestItems(sortedItems.slice(0, 3));
      } catch (error) {
        console.error('Error fetching zone items:', error);
        setError('Failed to fetch data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [zoneId, field]);

  if (isLoading) return <Text>Loading...</Text>;
  if (error) return <Text color="red.500">{error}</Text>;

  const getItemName = (item: ZoneItem) => {
    const defaultTitle = item.data['default-title'];
    if (typeof defaultTitle === 'object' && defaultTitle !== null) {
      return defaultTitle.value || 'Unnamed Item';
    }
    return defaultTitle || 'Unnamed Item';
  };

  return (
      <VStack spacing={4} align="stretch">
        <Box bg="orange.500" p={4} borderRadius="md">
          <Text fontSize="md" fontWeight="medium" color="white">
            Sum of the {fieldName} fields in {zoneName}
          </Text>
          <Text fontSize="4xl" fontWeight="bold" color="white">
          {(Number.isInteger(total) ? total : parseFloat(total.toFixed(2))).toString()}
          </Text>
        </Box>
        <Text fontSize="sm" fontWeight="medium" color="secondaryGray.600">
          Latest 3 items:
        </Text>
        {latestItems.map((item) => (
          <Flex key={item._id} justify="space-between">
            <Text fontSize="sm" color="secondaryGray.600" isTruncated maxWidth="60%">
              {getItemName(item)}:
            </Text>
            <Text fontSize="sm" fontWeight="medium">
            {(() => {
  const value = parseFloat(item.data[field]?.value || item.data[field] || '0');
  return (Number.isInteger(value) ? value : parseFloat(value.toFixed(2))).toString();
})()}
            </Text>
          </Flex>
        ))}
      </VStack>
  );
};

export default NumberPanel;