import { mode } from '@chakra-ui/theme-tools';
export const inputStyles = {
	components: {
		Input: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '16px'
				}
			},

			variants: {
				main: (props: any) => ({
					field: {
						bg: mode('transparent', 'navy.800')(props),
						border: '1px solid',
						color: mode('secondaryGray.900', 'white')(props),
						borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
						borderRadius: '16px',
						fontSize: 'sm',
						p: '20px',
						_placeholder: { color: 'secondaryGray.400' },
						_hover: {
							bg: mode('orange.100', 'orange.700')(props),
						  },
						  _focus: {
							bg: mode('orange.200', 'orange.600')(props),
						  },
					}
				}),
				auth: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('navy.700', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '1px solid',
						borderColor: mode('secondaryGray.100', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600', fontWeight: '400' }
					}
				}),
				authSecondary: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',
						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				search: () => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				zonePageInput: () => ({
					field: {
						border: '0px solid',
						borderColor: 'secondaryGray.100',
						padding: '10px',
						_focus: {
							border: '1px solid',
							borderColor: 'secondaryGray.600',  // Change color for focus state
							boxShadow: '0 0 0 5px secondaryGray.600',  // Add a glow effect
							
						  }
					}
				})
			}
		},
		NumberInput: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '16px'
				}
			},

			variants: {
				main: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				auth: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				authSecondary: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				search: () => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'secondaryGray.600' }
					}
				})
			}
		},
		Select: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '16px',
				}
			},

			variants: {
				main: (props: any) => ({
					field: {
						bg: mode('transparent', 'orange.500')(props),
						border: '1px solid',
						color: mode('secondaryGray.900', 'white')(props),
						borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.400' },
						_hover: {
							borderColor: mode('orange.500', 'orange.300')(props),
						  },
						  _focus: {
							borderColor: mode('orange.500', 'orange.300')(props),
							boxShadow: mode('0 0 0 1px orange.500', '0 0 0 1px orange.300')(props),
						  },
					},
					icon: {
						color: mode('secondaryGray.500', 'orange.500')(props),
					},
					option: {
						_hover: {
							bg: mode('orange.100', 'orange.500')(props),
						},
					}
				}),
				mini: (props: any) => ({
					field: {
						bg: mode('transparent', 'orange.500')(props),
						border: '0px solid transparent',
						fontSize: '0px',
						p: '10px',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						color: 'secondaryGray.600'
					}
				}),
				subtle: () => ({
					box: {
						width: 'unset'
					},
					field: {
						bg: 'transparent',
						border: '0px solid',
						color: 'secondaryGray.600',
						borderColor: 'transparent',
						width: 'max-content',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						color: 'secondaryGray.600'
					}
				}),
				transparent: (props: any) => ({
					field: {
						bg: 'transparent',
						border: '0px solid',
						width: 'min-content',
						color: mode('secondaryGray.600', 'secondaryGray.600')(props),
						borderColor: 'transparent',
						padding: '0px',
						paddingLeft: '8px',
						paddingRight: '20px',
						fontWeight: '700',
						fontSize: '14px',
						_placeholder: { color: 'secondaryGray.600' }
					},
					icon: {
						transform: 'none !important',
						position: 'unset !important',
						width: 'unset',
						color: 'secondaryGray.600',
						right: '0px'
					}
				}),
				auth: () => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				authSecondary: (props: any) => ({
					field: {
						bg: 'transparent',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600' }
					}
				}),
				search: (props: any) => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'secondaryGray.600' }
					}
				})
			}
		}
	}
};
