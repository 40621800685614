export const tableStyles = {
  components: {
    Table: {
      baseStyle: {
        td: {
          borderWidth: '1px',
          borderColor: '#ccc',
          borderStyle: 'solid',
          height: '39px',
          maxHeight: '39px',
          maxWidth: '130px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        th: {
          textTransform: 'capitalize',
          fontWeight: 'normal',
          letterSpacing: '0',
          height: '39px',
          maxWidth: '130px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  },
};
