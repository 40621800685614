import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Input, VStack, Text, Flex, Avatar} from '@chakra-ui/react';
import { aiService } from '../services/aiService';
import ReactMarkdown from 'react-markdown';

interface Message {
  content: string;
  sender: 'user' | 'ai';
}

interface AiChatProps {
  companyId: string;
}

const AiChat: React.FC<AiChatProps> = ({ companyId }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const latestMessageRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToLatestMessage = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToLatestMessage();
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    const userMessage: Message = { content: inputMessage, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    scrollToLatestMessage();

    try {
      const response = await aiService.askQuestion(inputMessage, companyId);
      const aiMessage: Message = { content: response, sender: 'ai' };
      setMessages(prevMessages => [...prevMessages, aiMessage]);
    } catch (error) {
      const errorMessage: Message = { content: 'Error getting response from AI.', sender: 'ai' };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack spacing={4} align="stretch" h="95%">
      <Box flex={1} overflowY="auto" p={2} ref={chatContainerRef}>
        {messages.map((message, index) => (
          <Flex
            key={index}
            mb={2}
            flexDirection={message.sender === 'user' ? 'row-reverse' : 'row'}
            ref={index === messages.length - 1 ? latestMessageRef : null}
          >
            <Avatar size="sm" color="black" name={message.sender === 'user' ? 'You' : 'AI'} bg={message.sender === 'user' ? 'orange.500' : 'horizonYellow.500'} />
            <Box
              maxW="70%"
              bg={message.sender === 'user' ? 'orange.100' : 'horizonYellow.100'}
              color="black"
              borderRadius="lg"
              p={2}
              ml={message.sender === 'user' ? 0 : 2}
              mr={message.sender === 'user' ? 2 : 0}
            >
              {message.sender === 'user' ? (
                <Text>{message.content}</Text>
              ) : (
                <ReactMarkdown>{message.content}</ReactMarkdown>
              )}
            </Box>
          </Flex>
        ))}
      </Box>
      <Flex>
        <Input
          placeholder="Ask any question about your data..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <Button onClick={handleSendMessage} isLoading={isLoading} ml={2} colorScheme="orange" variant="outline">
          Send
        </Button>
      </Flex>
    </VStack>
  );
};

export default AiChat;
