import React from 'react';
import { Flex, Text, Avatar, useColorModeValue, Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import AccountSettings from './AccountSettings';

interface UserProfile {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface ProfileProps {
  userProfile: UserProfile | null;
}

export default function Profile({ userProfile }: ProfileProps) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  if (!userProfile) {
    return <Card mb='20px'>Loading...</Card>;
  }

  return (
    <Box>
      <Card mb='20px'>
        <Flex align='center'>
          <Avatar h='87px' w='87px' me='20px' />
          <Flex direction='column'>
            <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
              {`${userProfile.firstName} ${userProfile.lastName}`}
            </Text>
            <Text mt='1px' color={textColorSecondary} fontSize='md'>
              {userProfile.email}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <AccountSettings />
    </Box>
  );
}