// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27currentColor%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpolyline points=%276 9 12 15 18 9%27%3e%3c/polyline%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Open Sans", sans-serif;
}

option {
  color: black;
  padding: 10px;
}


.zoneTable input, .zoneTable select, .zoneTable th{border-radius: 6px;font-size: 14px;    height: 30px;}
.zoneListTable td{font-size: 14px;}
.zoneListTable th{font-size: 14px; height: 49px;}
option:hover, option:focus {
  background-color: #FFA500;
  color: white;
}

select {
  appearance: none;
  padding-right: 30px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;;AAGA,mDAAmD,kBAAkB,CAAC,eAAe,KAAK,YAAY,CAAC;AACvG,kBAAkB,eAAe,CAAC;AAClC,kBAAkB,eAAe,EAAE,YAAY,CAAC;AAChD;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EAGE,gBAAgB;EAChB,mBAAmB;EACnB,yDAA2R;EAC3R,4BAA4B;EAC5B,sCAAsC;EACtC,oBAAoB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');\n\nbody {\n  font-family: \"Open Sans\", sans-serif;\n}\n\noption {\n  color: black;\n  padding: 10px;\n}\n\n\n.zoneTable input, .zoneTable select, .zoneTable th{border-radius: 6px;font-size: 14px;    height: 30px;}\n.zoneListTable td{font-size: 14px;}\n.zoneListTable th{font-size: 14px; height: 49px;}\noption:hover, option:focus {\n  background-color: #FFA500;\n  color: white;\n}\n\nselect {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  padding-right: 30px;\n  background-image: url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e\");\n  background-repeat: no-repeat;\n  background-position: right 10px center;\n  background-size: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
