import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const Dashboard: React.FC = () => {
  const { logout } = useAuth();

  return (
    <div>
      <h1>Dashboard</h1>
      <p>Welcome to your dashboard! hhhh</p>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Dashboard;