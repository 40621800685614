import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import BackgroundLogin from 'assets/img/auth/BackgroundLogin.png';
import axios from 'axios';
import { DibiLogo } from 'components/icons/Icons';

const AcceptInvitation = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/accept-invitation`, {
        token,
        firstName,
        lastName,
        password,
      });
      toast({
        title: 'Account created.',
        description: "We've created your account for you.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/auth/sign-in/default');
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: 'Unable to create your account. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: '70vh', md: '50vh' }}
        w={{ md: 'calc(100vw - 50px)' }}
        borderRadius={{ md: '15px' }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BackgroundLogin}
        bgSize="cover"
        mx={{ md: 'auto' }}
        mt={{ md: '14px' }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="30px"
        mb="10px"
      >
         <Stack
        direction="row"
        spacing="2px"
        alignItems="center"
        justify="center"
        mb="50px"
      >
        <Box h="26px" w="175px" >
          <DibiLogo />
        </Box>
      </Stack>
        <Heading color={textColor} fontSize="32px" mb="10px">
          Accept Invitation
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColor}
          fontWeight="normal"
          fontSize="14px"
        >
          Enter your details to create your account
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: '100px' }}
          bg={useColorModeValue('white', 'navy.800')}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              First Name
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your first name"
              mb="24px"
              size="lg"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Last Name
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your last name"
              mb="24px"
              size="lg"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={handleSubmit}
            >
              Create Account
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AcceptInvitation;