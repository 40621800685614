import React, { createContext, useState, useContext, useEffect } from 'react';
import { setAuthToken } from '../services/authService';
import { User } from '../types/user';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { resetZones } from 'store/slices/zoneSlice';

export interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  login: (token: string, refreshToken: string, userData: User) => void;
  logout: () => void;
  getToken: () => Promise<string>;
  refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  getToken: async () => '',
  refreshToken: async () => {
  },
});

const getUserInfo = (): User | null => {
  const userInfo = JSON.parse(localStorage.getItem("user_info") || "{}");

  return Object.keys(userInfo).length > 0 ? (userInfo as User) : null;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem('token') ? true : false);
  const [user, setUser] = useState<User | null>(getUserInfo());
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      setIsAuthenticated(true);
    }
  }, []);

  const login = (token: string, refreshToken: string, userData: User) => {

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem("user_info" , JSON.stringify(userData || {}))
    setAuthToken(token);
    setIsAuthenticated(true);
    setUser(userData);


    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
     // console.log('Decoded token:', decodedToken);
    } catch (error) {
     // console.error('Error decoding token:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem("user_info");
    setAuthToken('');
    setIsAuthenticated(false);
    setUser(null);
    dispatch(resetZones())
  };

  const getToken = async (): Promise<string> => {
    return localStorage.getItem('token') || '';
  };
  const refreshToken = async () => {
    const currentRefreshToken = localStorage.getItem('refreshToken');
    if (!currentRefreshToken) {
      throw new Error('No refresh token available');
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/refresh-token`, { refreshToken: currentRefreshToken });
      const { accessToken: newToken, refreshToken: newRefreshToken } = response.data;
      localStorage.setItem('token', newToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      return newToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout, getToken, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};