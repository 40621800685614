import React, { useState, useCallback } from 'react';
import {
  Button,
  VStack,
  Text,
  Box,
  Image,
  useSteps,
  Icon,
  Checkbox,
  Select,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  useColorModeValue,
  CloseButton
} from '@chakra-ui/react';
import { FaFileUpload, FaCheckCircle } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import CustomPopup from 'components/CustomPopup';
import { useDropzone } from 'react-dropzone';
import wizardImage from 'assets/img/wizard.png';
import { parse, isValid, format } from 'date-fns';

interface ImportWizardProps {
  isOpen: boolean;
  onClose: () => void;
  columns: string[];
  onImport: (data: any[], newColumns: { name: string; type: string }[], updatedColumns: string[]) => void;
}

const ImportWizard: React.FC<ImportWizardProps> = ({ isOpen, onClose, columns, onImport }) => {
  const [step, setStep] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [excelColumns, setExcelColumns] = useState<string[]>([]);
  const [columnMapping, setColumnMapping] = useState<{ [key: string]: string }>({});
  const [importedRowCount, setImportedRowCount] = useState(0);
  const [excludeFirstRow, setExcludeFirstRow] = useState(true);

  const steps = [
    { title: 'Select a file', description: 'Upload your Excel file' },
    { title: 'Headers Mapping', description: 'Please map the Excel columns to the table columns' },
    { title: 'Import', description: 'Review the results' },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: step,
    count: steps.length,
  });

  const handleFileUpload = (uploadedFile: File) => {
    const fileExtension = uploadedFile.name.split('.').pop()?.toLowerCase();
    if (['csv', 'xls', 'xlsx'].includes(fileExtension || '')) {
      setFile(uploadedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
        if (parsedData.length > 0) {
          const headers = parsedData[0] as string[];
          setExcelColumns(headers);
          setColumnMapping(
            headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {})
          );
        }
      };
      reader.readAsArrayBuffer(uploadedFile);
    } else {
      alert('Please upload a CSV, XLS, or XLSX file.');
    }
  };

  const handleColumnMapping = (excelColumn: string, zoneColumn: string) => {
    setColumnMapping((prev) => ({ ...prev, [excelColumn]: zoneColumn }));
  };

  const formatNumber = (value: any) => {
    if (value == null) return null;
    if (typeof value === 'string') {
      // Remove all non-numeric characters except for the decimal point
      const cleanedValue = value.replace(/[^0-9.]/g, '');
      return Number(cleanedValue);
    }
    return typeof value === 'number' ? value : null;
  };

  const isValidDateString = (value: string): boolean => {
    // Add regex or other validation logic here
    return /^\d{1,2}[-/.]\d{1,2}[-/.]\d{2,4}$/.test(value);
  };

  const formatDate = (value: any): string | null => {
    if (value == null) return null;
    
    let date: Date | null = null;

    if (value instanceof Date) {
      date = value;
    } else if (typeof value === 'number') {
      date = new Date((value - 25569) * 86400 * 1000);
    } else if (typeof value === 'string') {
      const formats = ['yyyy-MM-dd', 'dd/MM/yyyy', 'MM/dd/yyyy', 'dd-MM-yyyy', 'MM-dd-yyyy', 'dd.MM.yyyy', 'MM.dd.yyyy'];
      for (const formatString of formats) {
        const parsedDate = parse(value, formatString, new Date());
        if (isValid(parsedDate)) {
          date = parsedDate;
          break;
        }
      }
    }

    if (date && isValid(date)) {
      return format(date, 'yyyy-MM-dd');
    }

    console.warn(`Unable to parse date: ${value}`);
    return null;
  };

  const handleImport = () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null }) as any[][];

      const startIndex = excludeFirstRow ? 1 : 0;
      const importedData = parsedData.slice(startIndex)
        .filter(row => row.some(cell => cell !== null && cell !== '' && cell !== undefined))
        .map((row) => {
          const newRow: { [key: string]: any } = {};
          excelColumns.forEach((excelColumn, index) => {
            const zoneColumn = columnMapping[excelColumn];
            if (zoneColumn) {
              const cellValue = row[index];
              if (cellValue !== null && cellValue !== '' && cellValue !== undefined) {
                if (zoneColumn.startsWith('new_number_')) {
                  newRow[zoneColumn.replace('new_number_', '')] = formatNumber(cellValue);
                } else if (zoneColumn.startsWith('new_date_')) {
                  const formattedDate = formatDate(cellValue);
                  if (formattedDate) {
                    newRow[zoneColumn.replace('new_date_', '')] = formattedDate;
                  } else {
                    console.warn(`Failed to format date for column ${zoneColumn}, value: ${cellValue}`);
                  }
                } else if (zoneColumn.startsWith('new_select_')) {
                  newRow[zoneColumn.replace('new_select_', '')] = String(cellValue);
                } else {
                  newRow[zoneColumn.replace('new_text_', '')] = String(cellValue);
                }
              }
            }
          });
          return newRow;
        })
        .filter(row => Object.keys(row).length > 0);


      const newColumns = Object.entries(columnMapping)
      .filter(([_, value]) => value.startsWith('new_'))
      .map(([_, value]) => {
        const [, type, name] = value.split('_');
        return {
          name,
          type: type.toLowerCase() // Ensure the type is lowercase
        };
      });

      const updatedColumns = [...columns, ...newColumns.map(col => col.name)];

      setImportedRowCount(importedData.length);
      onImport(importedData, newColumns, updatedColumns);
      setStep(2);
      setActiveStep(2);
    };
    reader.readAsArrayBuffer(file);
  };

  const DropFileZone: React.FC<{ onFileUpload: (file: File) => void }> = ({ onFileUpload }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
          onFileUpload(acceptedFiles[0]);
        }
      }, [onFileUpload]),
      accept: {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-excel': ['.xls'],
        'text/csv': ['.csv']
      },
      multiple: false
    });

    return (
      <Box
        {...getRootProps()}
        borderWidth={2}
        borderStyle="dashed"
        borderRadius="md"
        p={8}
        textAlign="center"
        bg={isDragActive ? 'gray.100' : 'white'}
      >
        <input {...getInputProps()} />
        <VStack spacing={4}>
          <FaFileUpload size={32} color="Green.400" />
          <Text fontWeight="bold">Drop your Excel file here</Text>
          <Text color="brand.500">or upload from your computer</Text>
          <Text fontSize="sm" color="gray.500">Only CSV, XLS, or XLSX are allowed</Text>
        </VStack>
      </Box>
    );
  };

  const textColor = useColorModeValue('gray.700', 'white');
  const activeColor = useColorModeValue('orange.500', 'orange.200');
  const inactiveColor = useColorModeValue('gray.200', 'gray.600');
  return (
    <CustomPopup isOpen={isOpen} onClose={onClose} hideCloseButton>
      <VStack width="600px" maxWidth="600px" spacing={0}>
        <Box width="100%" bg="gray.200" position="relative">
          <Flex justifyContent="center" alignItems="center" height="50px">
            <Image src={wizardImage} alt="Wizard" height="33px" objectFit="contain" />
          </Flex>
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={onClose}
          />
        </Box>
        <Box width="100%" padding="10">
          <Text fontSize="2xl" align="center" fontWeight="normal" mb="4">Import data from Excel</Text>
         <Box display="flex" justifyContent="center" width="100%">
          <Flex align="center" mb="8" width="300px">
            {steps.map((s, i) => (
              <React.Fragment key={i}>
                <Flex direction="column" align="center">
                  <Box
                    w="15px"
                    h="15px"
                    borderRadius="full"
                    bg={i <= step ? activeColor : inactiveColor}
                  />
                  
                </Flex>
                {i < steps.length - 1 && (
                  <Box
                    flex="1"
                    h="2px"
                    bg={i < step ? activeColor : inactiveColor}
                    mx="2"
                  />
                )}
              </React.Fragment>
            ))}
          </Flex>
          </Box>
          <Text fontSize="lg" align="center" color="orange.500" mb={0}>{steps[step].title}</Text>
          <Text fontSize="ms" align="center" mb={10}>{steps[step].description}</Text>

          {step === 0 && (
            <VStack spacing={8} align="center">
              <DropFileZone onFileUpload={handleFileUpload} />
              {file && <Text fontWeight="bold">File uploaded successfully<br /> <span style={{ fontWeight: "normal", textAlign: "center" }}>{file.name}</span></Text>}
            </VStack>
          )}

          {step === 1 && (
            <VStack spacing={4} align="stretch">
             <FormControl>
              <Checkbox
                isChecked={excludeFirstRow}
                onChange={(e) => setExcludeFirstRow(e.target.checked)}
              >
                Exclude first row of spreadsheet from import
              </Checkbox>
            </FormControl>
            
              {excelColumns.map((excelColumn) => (
               <FormControl key={excelColumn}>
                <Flex alignItems="center">
                 <FormLabel flex="1" mb={0}>{excelColumn}</FormLabel>
                 <Select
                   placeholder="Select Zone column"
                   value={columnMapping[excelColumn]}
                   width="300px"
                   onChange={(e) => handleColumnMapping(excelColumn, e.target.value)}
                   
                 >
                   {columns.map((column) => (
                     <option key={column} value={column}>
                       {column}
                     </option>
                   ))}
                   <option value={`new_text_${excelColumn}`}>New Text Column</option>
                   <option value={`new_number_${excelColumn}`}>New Number Column</option>
                   <option value={`new_select_${excelColumn}`}>New Select Column</option>
                   <option value={`new_date_${excelColumn}`}>New Date Column</option>
                 </Select>
                 </Flex>
               </FormControl>
              ))}
            </VStack>
          )}

          {step === 2 && (
            <VStack spacing={4} align="center">
              <Icon as={FaCheckCircle} color="green.500" boxSize={16} />
              <Text fontSize="xl" fontWeight="bold">
                Import Successful
              </Text>
              <Text>
                {importedRowCount} rows have been imported into your Zone table.
              </Text>
            </VStack>
          )}

          <HStack mt="4" justifyContent="space-between">
            {step > 0 && (
              <Button
                onClick={() => {
                  setStep(step - 1);
                  setActiveStep(activeStep - 1);
                }}
                variant="outline"
                fontWeight="normal"
                colorScheme="black"
                 color="gray.700"
                _hover={{ bg: 'gray.200' }}
              >
                Previous
              </Button>
            )}
            {step < 2 && (
              <Button
                colorScheme="orange"
                fontWeight="normal"
                onClick={() => {
                  if (step === 1) {
                    handleImport();
                  } else {
                    setStep(step + 1);
                    setActiveStep(activeStep + 1);
                  }
                }}
                isDisabled={step === 0 && !file}
              >
                {step === 1 ? 'Import' : 'Next'}
              </Button>
            )}
            {step === 2 && (
              <Button colorScheme="orange" onClick={onClose}>
                Close
              </Button>
            )}
          </HStack>
        </Box>
      </VStack>
    </CustomPopup>
  );
};

export default ImportWizard;