import api from './api';

export const aiService = {
  askQuestion: async (question: string, companyId: string) => {
    try {
      const response = await api.post('/api/zones/ask-ai', { question, companyId });
      return response.data.answer;
    } catch (error) {
      console.error('Error asking AI:', error);
      throw error;
    }
  },
};