import { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';

const useGlobalIntercept = () => {
  const { getToken , refreshToken} = useAuth();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
    async  (req) => {
        const token = await getToken();
      if (token) {
        req.headers['Authorization'] = `Bearer ${token}`;
      }
        return req;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response; 
      },
     async (error) => {
        if (error.response && error.response.status === 401) {
          try {
            const response = await axios.post(process.env.REACT_APP_API_URL+'/api/auth/refresh-token', { refreshToken: localStorage.getItem('refreshToken') });
            if(response && response.data){
              const { accessToken: newToken, refreshToken: newRefreshToken } = response.data;
              localStorage.setItem('token', newToken);
              localStorage.setItem('refreshToken', newRefreshToken);
            }
          } catch (err) {
            console.error('Refresh token failed', err);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};

export default useGlobalIntercept;
