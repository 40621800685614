import api from './api';
import { DashboardPanel } from '../types/dashboard';

export const dashboardService = {
  async createDashboardPanel(panelData: Omit<DashboardPanel, 'id'>): Promise<DashboardPanel> {
    try {
      const response = await api.post('/api/dashboard-panels', panelData);
      return response.data;
    } catch (error) {
      console.error('Error creating dashboard panel:', error);
      throw error;
    }
  },

  async getAllDashboardPanels(): Promise<DashboardPanel[]> {
    try {
      const response = await api.get('/api/dashboard-panels');
      return response.data;
    } catch (error) {
      console.error('Error fetching dashboard panels:', error);
      throw error;
    }
  },

  async updateDashboardPanel(id: string, panelData: Partial<DashboardPanel>): Promise<DashboardPanel> {
    const response = await api.put(`/api/dashboard-panels/${id}`, panelData);
    return response.data;
  },

  async deleteDashboardPanel(id: string): Promise<void> {
    await api.delete(`/api/dashboard-panels/${id}`);
  },
};