import React, { useState } from 'react';
import { Button, Flex, FormControl, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useAxiosWithAuth } from '../../../../../../hooks/useAxiosWithAuth';
import { UserProfile } from 'types/user';

interface PasswordProps {
  userProfile: UserProfile | null;
  mb?: string;
}

export default function Password({ userProfile, mb }: PasswordProps) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const toast = useToast();
  const axiosInstance = useAxiosWithAuth();

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: 'Passwords do not match',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await axiosInstance.put('/users/change-password', {
        oldPassword,
        newPassword,
      });
      toast({
        title: 'Password changed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      toast({
        title: 'Error changing password',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!userProfile) {
    return <Card mb={mb}>Loading...</Card>;
  }

  return (
    <FormControl>
      <Card mb={mb}>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Change password
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can set your new password
          </Text>
        </Flex>
        <FormControl>
          <Flex flexDirection='column'>
            <InputField
              mb='25px'
              id='old'
              label='Old Password'
              placeholder='Enter old password'
              type='password'
              value={oldPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)}
            />
            <InputField
              mb='25px'
              id='new'
              label='New Password'
              placeholder='Enter new password'
              type='password'
              value={newPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
            />
            <InputField
              mb='25px'
              id='confirm'
              label='New Password Confirmation'
              placeholder='Confirm new password'
              type='password'
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
            />
          </Flex>
        </FormControl>
        <Button
          borderRadius='16px'
          minW='183px'
          h='44px'
          ms='auto'
          mt='33px'
          variant='brand'
          color='white'
          fontSize='sm'
          fontWeight='500'
          _hover={{ bg: 'brand.600' }}
          _active={{ bg: 'brand.500' }}
          _focus={{ bg: 'brand.500' }}
          onClick={handleChangePassword}
        >
          Change Password
        </Button>
      </Card>
    </FormControl>
  );
}
