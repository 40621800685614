import React, { useCallback, useMemo, useState } from 'react';
import { ColumnHelper } from '@tanstack/react-table';
import { Input,Select, NumberInput, NumberInputField, Tooltip, Box} from '@chakra-ui/react';
import SortableHeader from 'components/SortableHeader';
import AddColumnHeader from 'components/AddColumnHeader';
import DatePickerField from '../../components/fields/DatePickerField';
import { RowData } from '@tanstack/react-table';
import 'react-datepicker/dist/react-datepicker.css';
import LabelField from 'components/fields/LabelField';
import '../../App.css';
import { debounce } from 'lodash';

type Column = string;

interface UseTableColumnsProps {
  columns: Column[];
  columnHelper: ColumnHelper<any>; // Adjust the type based on your data structure
  borderColor: string;
  hoveredColumn: string | null;
  setHoveredColumn: React.Dispatch<React.SetStateAction<string | null>>;
  editingHeader: string | null;
  renameColumn: (column: string, newName: string) => void;
  activePopover: { id: string | null, type: string | null };
  setActivePopover: React.Dispatch<React.SetStateAction<{ id: string | null, type: 'columnOptions' | 'editSelectOptions' | 'editCalculation' | null }>>;
  deleteColumn: (column: string) => void;
  setEditingHeader: React.Dispatch<React.SetStateAction<string | null>>;
  selectOptions: Record<string, string[]>;
  manageSelectOptions: (column: string, options: string[]) => void;
  updateData: (rowIndex: number, columnId: string, value: string | number) => void;
  columnSizing: Record<string, number>;
  addColumn: (type: string) => void;
  columnTypes: { [key: string]: string };
}

interface TableColumns {
  regularColumns: any[]; // Adjust this type based on the column structure
  addColumnHeader: any;  // Adjust this type based on the column structure
}

const DEFAULT_COLUMN_WIDTH = 150;

// Add this type declaration
declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: string | number) => void;
  }
}

const safeToString = (value: any): string => value?.toString() ?? '';

const useTableColumns = ({
  columns,
  columnHelper,
  borderColor,
  hoveredColumn,
  setHoveredColumn,
  editingHeader,
  renameColumn,
  selectOptions,
  updateData,
  columnSizing,
  addColumn,
  columnTypes,
}: UseTableColumnsProps): TableColumns => {
  const tableColumns = useMemo(() => {
    const regularColumns = columns.map((column) => {
      return columnHelper.accessor(column, {
        header: ({ column: columnObj }) => (
          <SortableHeader
            header={columnObj}
            borderColor={borderColor}
            isHovered={hoveredColumn === column}
            onMouseEnter={() => setHoveredColumn(column)}
            onMouseLeave={() => setHoveredColumn(null)}
          >
            {editingHeader === column ? (
              <Input
                size="sm"
                defaultValue={column}
                onBlur={(e) => renameColumn(column, e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    renameColumn(column, e.currentTarget.value);
                  }
                }}
                autoFocus
              />
            ) : (
              <>
                <span>{column}</span>

               
              </>
            )}
          </SortableHeader>
        ),
        cell: ({ row, column, table }) => {
          const initialValue = row.getValue(column.id) as string;
          const columnType = columnTypes[column.id];

          if (Array.isArray(initialValue)) {
            // Join multiple checkbox values with commas
            return initialValue.join(', ');
          }

          return (
            <CellRenderer
              initialValue={safeToString(initialValue)}
              columnType={columnType}
              column={column}
              row={row}
              table={table}
              selectOptions={selectOptions}
              updateData={updateData}
            />
          );
        },
        size: columnSizing[column] || DEFAULT_COLUMN_WIDTH,
      })
  });

    const addColumnHeader = columnHelper.accessor('addColumn', {
      id: 'addColumn',
      header: () => <AddColumnHeader onAddColumn={addColumn} borderColor={borderColor} />,
      cell: () => null,
    });

    return { regularColumns, addColumnHeader };
  }, [columns, hoveredColumn, borderColor, updateData, selectOptions, addColumn]);

  return tableColumns;
};
interface SelectOption {
  formula?: string;
  fields?: string[];
  operators?: string[];
}

interface CellRendererProps {
  initialValue: string;
  columnType: string;
  column: any; // Replace 'any' with the correct type from @tanstack/react-table if available
  row: any; // Replace 'any' with the correct type from @tanstack/react-table if available
  table: any; // Replace 'any' with the correct type from @tanstack/react-table if available
  selectOptions: Record<string, string[]>;
  updateData: (rowIndex: number, columnId: string, value: string | number) => void;
}
const CellRenderer: React.FC<CellRendererProps> = React.memo(({ initialValue, columnType, column, row, table, selectOptions, updateData }) => {
  const [value, setValue] = useState(initialValue);

  const debouncedFetchData = useCallback(
    debounce((value: string) => updateData(row.index, column.id, value), 1000),
    [row.index, column.id, updateData]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedFetchData(e.target.value);
  };

  const handleNumberChange = (valueAsString: string) => {
    setValue(valueAsString);
    debouncedFetchData(valueAsString);
  };

  if (columnType === 'select' && selectOptions[column.id]) {
    return (
      <Tooltip
        bg="gray.800"
        label={initialValue}
        isDisabled={!initialValue || initialValue.length <= 20}
        placement="top"
        hasArrow
      >
        <Select
          value={initialValue}
          onChange={(e) => updateData(row.index, column.id, e.target.value)}
          id={`input-id-${Math.random() * 9999}`}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
        >
          {selectOptions[column.id].map((option: string) => (
            <option id={`input-id-${Math.random() * 9999}`} key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </Tooltip>
    );
  } else if (columnType === 'number') {
    return (
      <NumberInput  value={value} onChange={handleNumberChange} id={`input-id-${Math.random() * 9999}`}>
        <NumberInputField id={`input-id-${Math.random() * 9999}`}/>
      </NumberInput>
    );
  } else if (columnType === 'date') {
    return (
      <DatePickerField
        initialValue={initialValue}
        onChange={(formattedDate) => updateData(row.index, column.id, formattedDate)}
        isDisabled={false}
      />
    );
  } else if (columnType === "label"){
    return (
      <LabelField
        options={selectOptions[column.id] as unknown as { label: string; color: string }[]}
        value={initialValue}
        onChange={(val) => updateData(row.index, column.id, val)}
        isDisabled={false}
        updateFieldOptions={() => {}}
        variant="table"
      />
    );
  } else if (columnType === 'calculation') {
    const calculateValue = () => {
      const option = selectOptions[column.id] as SelectOption;
      const formula = option?.formula;
      const fields = option?.fields;
      const operators = option?.operators;

      if (!formula || !fields || !operators) return 'N/A';

      let expression = formula;

      // Replace field placeholders with actual values
      const hasDecimal = fields.some(field => {
        const fieldValue = field in row.original 
          ? parseFloat(row.original[field] || '0') 
          : parseFloat(field);
        return !isNaN(fieldValue) && fieldValue % 1 !== 0;
      });

      fields.forEach((field) => {
        const fieldValue = field in row.original 
          ? parseFloat(row.original[field] || '0') 
          : parseFloat(field);
        
        if (isNaN(fieldValue)) {
          //console.warn(`Invalid field value for ${field}:`, row.original[field]);
          return 'Error';
        }

        expression = expression.replace(`{${field}}`, fieldValue.toString());
      });

      // Evaluate the final expression
      try {
        const result = eval(expression);
        if (isNaN(result)) return 'Error';
        
       // Format the result
        const option = selectOptions[column.id] as SelectOption;
        if ('formatAsInteger' in option && option.formatAsInteger) {
          return Math.round(result).toString();
        } else {
          return (Number.isInteger(result) ? result : parseFloat(result.toFixed(2))).toString();
        }
      } catch (error) {
       //console.error('Error evaluating formula:', error);
        return 'Error';
      }
    };

    return (
      <Input
        value={calculateValue()}
        isReadOnly
        id={`input-id-${Math.random() * 9999}`}
      />
    );
  } else {
    return (
      <Input
        value={value}
        onChange={handleChange}
        id={`input-id-${Math.random() * 9999}`}
      />
    );
  }
});

export default useTableColumns;