// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #FE7053!important; 
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
}

.dibiTable th{
  font-size: 15px;
  color:#707070;
  font-weight:normal;
  border:1px #f0f0f0 solid;
}

input:focus-visible, textarea:focus-visible{
  border-color: #FE7053!important;
  box-shadow:none!important;
}

/* Global scrollbar styles */
html::-webkit-scrollbar,
body::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Firefox scrollbar styles */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;AACpC,kBAAkB;AAClB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA,4BAA4B;AAC5B;;;EAGE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;;;EAGE,6BAA6B;AAC/B;;AAEA;;;EAGE,oCAAoC;AACtC;;AAEA,6BAA6B;AAC7B;EACE,qBAAqB;EACrB,+CAA+C;AACjD","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Open Sans\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\noverflow-y: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\na {\n  color: #FE7053!important; \n  text-decoration: none;\n  transition: color 0.3s ease;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n.dibiTable th{\n  font-size: 15px;\n  color:#707070;\n  font-weight:normal;\n  border:1px #f0f0f0 solid;\n}\n\ninput:focus-visible, textarea:focus-visible{\n  border-color: #FE7053!important;\n  box-shadow:none!important;\n}\n\n/* Global scrollbar styles */\nhtml::-webkit-scrollbar,\nbody::-webkit-scrollbar,\n*::-webkit-scrollbar {\n  width: 8px;\n  background-color: transparent;\n}\n\nhtml::-webkit-scrollbar-track,\nbody::-webkit-scrollbar-track,\n*::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\nhtml::-webkit-scrollbar-thumb,\nbody::-webkit-scrollbar-thumb,\n*::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.2);\n}\n\n/* Firefox scrollbar styles */\n* {\n  scrollbar-width: thin;\n  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
