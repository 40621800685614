// chakra imports
import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
// import { FlexibleRoutesType } from 'types/routes';
import { FlexibleRoutesType } from 'types/types';
import { useAuth } from '../../../contexts/AuthContext';
// FUNCTIONS

function SidebarContent(props: {
  routes: FlexibleRoutesType[];
  hovered?: boolean;
  mini?: boolean;
}) {
  const { routes, mini, hovered } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const { user } = useAuth();
  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="0px" borderRadius="30px">
      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
          ps={
            mini === false
              ? '0px'
              : mini === true && hovered === true
              ? '0px'
              : '16px'
          }
          pe={{ md: '16px', '2xl': '1px' }}
          ms={mini && hovered === false ? '-16px' : 'unset'}
        >
          <Links 
            mini={mini} 
            hovered={hovered} 
            routes={routes.filter(route => 
              route.name !== 'Companies' || user?.role === 'superAdmin'
            ) as RoutesType[]} 
          />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;