import { useState } from 'react';
import { Textarea, TextProps} from '@chakra-ui/react';

interface Field {
  id: string;
  type: string;
  label: string;
  appearInTable: boolean;
  required: boolean;
  repeater: boolean;
  options?: string[] | { zoneId: string, options: string[] } | string[];
  tabId: string;
  order: number;
  position: 'left' | 'right';
}
interface IFieldsEditor extends TextProps {
  field: string;
  side?: any;
  updateFieldOptionsTab?:
  (side: any, fieldId: string, newOptions: string[]) => void,

  updateFieldOptionsTable?: (columnId: string, options: string[]) => void,

  type: 'tabs' | 'table';
  valId?: string
}

const FieldsEditor: React.FC<IFieldsEditor> = ({ type, field, side = "", valId = "", updateFieldOptionsTab = () => { }, updateFieldOptionsTable = () => { } }) => {
  const [value, setValue] = useState<string>(field);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
    const newOptions = e.target.value.split('\n').filter(option => option.trim() !== '').map(option => {
      const [label, color] = option.split('|');
      return { label: label.trim(), color: color ? color.trim() : '#000000' };
    });
    if (type === 'tabs') {
      updateFieldOptionsTab(side, valId, newOptions.map(option => option.label));
    }

    if (type === "table") {
      updateFieldOptionsTable(valId, newOptions.map(option => option.label));
    }
  };

  return (
    <Textarea
      minHeight="250px"
      value={value}
      onChange={handleChange}
      placeholder="Add one option per line. Format: Label|#ColorHex"
      size="sm"
    />
  );
};

export default FieldsEditor;
