/* eslint-disable */
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useState, useEffect, useMemo } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { useLocation } from 'react-router-dom';
import TableIcon from 'assets/svg/TableIcon';
import GridIcon from 'assets/svg/GridIcon';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { RootState } from 'store';

export default function AdminNavbar(props: {
  secondary: boolean;
  currentZoneName: string;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
  [x: string]: any;
}) {
  const [scrolled, setScrolled] = useState(false);
  const [companyData, setCompanyData] = useState({ logo: '', name: '' });
  const [displayText, setDisplayText] = useState('');
  const activeZone = useSelector((state: RootState) => state.zone.activeZone);
  const location = useLocation();

  useEffect(() => {
    const type = (new URLSearchParams(location.search))
    if (location?.pathname?.includes("/admin/dashboards")) {
      console.log("object");
      setDisplayText('Dashboard');
    } else if(location?.pathname?.includes("/admin/reports")) {
      setDisplayText('Reports');
    } else if (location?.pathname?.includes("/admin/zones")&& !location?.pathname?.includes("/edit")&& type?.size==0) {
      setDisplayText('Zones')
    } else if (location?.pathname?.includes("/admin/zones") && type?.size>0) {
      setDisplayText(activeZone);
    } else if(location?.pathname?.includes("/edit")) {
      setDisplayText(activeZone);
    }
  }, [location, activeZone]);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);
    fetchCompanyData();

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  }, []);

  useEffect(() => {
    const handleLogoUpdate = () => {
      fetchCompanyData();
    };

    window.addEventListener('companyLogoUpdated', handleLogoUpdate);

    return () => {
      window.removeEventListener('companyLogoUpdated', handleLogoUpdate);
    };
  }, []);

  const fetchCompanyData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/info`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCompanyData(response.data);
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  const {
    secondary,
    mini,
    setMini,
    theme,
    setTheme,
    hovered,
    currentZoneName,
    brandText,
  } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('gray.900', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed' as const;
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.2)',
    'rgba(11,20,55,0.5)',
  );
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const Icon = useMemo(() => {
    // Don't show icon for these main pages
    if (location.pathname === "/admin/dashboards" ||
        location.pathname === "/admin/reports" ||
        (location.pathname === "/admin/zones" && !(new URLSearchParams(location.search)).size)) {
      return null;
    }

    const type = (new URLSearchParams(location.search)).get("type");
    // Show GridIcon for zone items (URL contains /item/) or edit pages
    if (location.pathname.includes("/item/") || location.pathname.includes("/edit")) {
      return <GridIcon boxSize={"34px"} style={{marginTop: "5px"}}/>;
    }
    
    return type === "table" ? <TableIcon boxSize={"34px"} style={{marginTop: "5px"}}/> : <GridIcon boxSize={"34px"} style={{marginTop: "5px"}}/>
  }, [location, activeZone])



  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      /* transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s" */
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '0px',
      }}
      pt="8px"
      top={{ base: '12px', md: '16px', xl: '18px' }}
      w={
        mini === false
          ? {
            base: 'calc(100vw - 6%)',
            md: 'calc(100vw - 8%)',
            lg: 'calc(100vw - 6%)',
            xl: 'calc(100vw - 350px)',
            '2xl': 'calc(100vw - 346px)',
          }
          : mini === true && hovered === true
            ? {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 350px)',
              '2xl': 'calc(100vw - 346px)',
            }
            : {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 185px)',
              '2xl': 'calc(100vw - 200px)',
            }
      }
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box mb={{ sm: '8px', md: '0px'  }} sx={{display: "flex" , placeItems: "center" , gap: "10px" , height: "40px"}}>
          {Icon}
          <Text
            textAlign={useBreakpointValue({ base: 'center', xl: 'left' })}
            color={mainText}
            fontWeight='bold'
            fontSize='34px'
            mt='3px'
          >
            {displayText?.length ? displayText : "" }
          </Text>
        </Box>
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLinks
            mini={mini}
            setMini={setMini}
            theme={theme}
            setTheme={setTheme}
            secondary={props.secondary}
            companyInfo={{
              logo: companyData.logo,
              name: companyData.name,
            }}
          />
        </Box>
      </Flex>
      {/*  <Flex
        pe={{ sm: '0px', md: '16px' }}
        w={{ sm: '100%', md: 'auto' }}
        alignItems="center"
        flexDirection="row"
      >
        {props.currentZoneName && (
          <Text
            display={{ sm: 'none', md: 'flex' }}
            fontSize="sm"
            fontWeight="bold"
            color={mainText}
            mr={4}
          >
            Current Zone: {props.currentZoneName}
          </Text>
        )}
      </Flex> */}
    </Box>
  );
}
