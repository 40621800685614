import { configureStore } from '@reduxjs/toolkit';
import zoneSlice from './slices/zoneSlice';

export const store = configureStore({
  reducer: {
    zone: zoneSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;