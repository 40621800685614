import React from 'react';
import AdminRoute from 'components/AdminRoute';
import Zones from './index';

export default function ProtectedZones({ setCurrentZoneName }: { setCurrentZoneName: (name: string) => void }) {
  return (
    <AdminRoute>
      <Zones setCurrentZoneName={setCurrentZoneName} />
    </AdminRoute>
  );
}