import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, useColorModeValue, Icon } from '@chakra-ui/react';
import axios from 'axios';
import { MdOutlineCloudUpload } from 'react-icons/md';

interface FileUploadProps {
  onUploadSuccess: (fileUrl: string) => void;
  onUploadError: (error: string) => void;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
  uploadUrl: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onUploadSuccess,
  onUploadError,
  acceptedFileTypes = ['image/*'],
  maxFileSize = 5 * 1024 * 1024, // 5MB
  uploadUrl,
}) => {
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file.size > maxFileSize) {
      onUploadError(`File size should not exceed ${maxFileSize / (1024 * 1024)}MB`);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });
      onUploadSuccess(response.data.fileUrl);
    } catch (error) {
      onUploadError('Error uploading file. Please try again.');
    }
  }, [onUploadSuccess, onUploadError, maxFileSize, uploadUrl]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
    maxSize: maxFileSize,
  });

  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const bgColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <Box
      {...getRootProps()}
      borderWidth={2}
      borderStyle="dashed"
      borderColor={borderColor}
      borderRadius="md"
      p={4}
      textAlign="center"
      bg={bgColor}
      cursor="pointer"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <input {...getInputProps()} id="logo-upload" />
      <Icon as={MdOutlineCloudUpload} w="40px" h="40px" color="gray.500" mb={2} />
      {isDragActive ? (
        <Text fontSize="sm">Drop the logo here...</Text>
      ) : (
        <Text fontSize="sm">Click or drag to upload logo</Text>
      )}
    </Box>
  );
};

export default FileUpload;