import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface SVGIconProps extends IconProps {
  viewBox?: string;
  color?: string;

}

const GridIcon: React.FC<SVGIconProps> = ({ viewBox = '0 0 24 24', color = 'currentColor', ...props }) => {
  return (
    <Icon 
      viewBox={viewBox} 
      color={color} 
      style={{marginTop: "-8px"}}
      {...props}
     
    >
      <svg width="100%" height="100%" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_440_1717)">
          <g clipPath="url(#clip1_440_1717)">
          <path d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z" fill="#FFB547"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_440_1717">
            <rect width="24" height="24" fill="white" />
          </clipPath>
          <clipPath id="clip1_440_1717">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

export default GridIcon;
