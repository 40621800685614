import React from 'react';
import { Box, Flex, CloseButton } from '@chakra-ui/react';

interface CustomPopupProps {
  isOpen: boolean;
  onClose: () => void;
  hideCloseButton?: boolean;
  children: React.ReactNode;
}

const CustomPopup: React.FC<CustomPopupProps> = ({ isOpen, onClose, hideCloseButton, children }) => {
  if (!isOpen) return null;

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      zIndex="1000"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        backgroundColor="white"
        borderRadius="md"
        padding="0"
        maxWidth="90%"
        maxHeight="90%"
        overflow="auto"
      >
        {!hideCloseButton && <Flex justifyContent="flex-end">
          <CloseButton onClick={onClose} />
        </Flex>}
        <Box maxHeight="calc(90vh - 40px)" overflowY="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomPopup;