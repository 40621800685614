import React, { useEffect, useState } from 'react';
import { PieChart as RechartsPieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { zoneService } from 'services/zoneService';

interface PieChartProps {
  zoneId: string;
  field: string;
}

interface ChartDataItem {
  name: string;
  value: number;
}

const COLORS = [
    '#861B81', // Deep Purple
    '#EE5D50', // Coral Red
    '#FFBB28', // Bright Yellow
    '#4CAF50', // Green
    '#2196F3', // Blue
    '#FF8042', // Orange
    '#9C27B0', // Purple
    '#00BCD4', // Cyan
    '#FFC107', // Amber
    '#3F51B5', // Indigo
    '#E91E63', // Pink
    '#009688'  // Teal
  ];

const PieChart: React.FC<PieChartProps> = ({ zoneId, field }) => {
  const [data, setData] = useState<ChartDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const zoneData = await zoneService.getZoneById(zoneId);

        if (zoneData.data.type === 'table') {
          // For table-type zones, use the data directly from zoneData
          const tableData = zoneData.data.data || [];

          if (!Array.isArray(tableData) || tableData.length === 0) {
            throw new Error('No items found for the given zoneId');
          }
          const freq: { [key: string]: number } = {};
          const chartData = tableData.forEach((item: any) => {
            const xValue = item[field] || 'Unknown';
            freq[xValue] = (freq[xValue] || 0) + 1;
          });


          const formattedData: ChartDataItem[] = Object.entries(freq).map(([name, value]) => ({
            name,
            value: Number(value),
          }));


  setData(formattedData);

  
        } else {
          try {
            const zoneItems = await zoneService.getZoneItems(zoneId);
    
            if (!Array.isArray(zoneItems) || zoneItems.length === 0) {
              throw new Error('No items found for the given zoneId');
            }
    
            let chartData: Record<string, number> = {};
    
            zoneItems.forEach((item: any) => {
              if (item && typeof item.data === 'object' && field in item.data) {
                const value = String(item.data[field].value || item.data[field]);
                chartData[value] = (chartData[value] || 0) + 1;
              }
            });
    
            const formattedData: ChartDataItem[] = Object.entries(chartData).map(([name, value]) => ({
              name,
              value: Number(value),
            }));
    
            setData(formattedData);
          } catch (error) {
            console.error('Error fetching zone items:', error);
            if (error instanceof Error) {
              setError(`Error: ${error.message}`);
            } else {
              setError('An unknown error occurred');
            }
          } finally {
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching zone items:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };


    const fetchData_ = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const zoneItems = await zoneService.getZoneItems(zoneId);

        if (!Array.isArray(zoneItems) || zoneItems.length === 0) {
          throw new Error('No items found for the given zoneId');
        }

        let chartData: Record<string, number> = {};

        zoneItems.forEach((item: any) => {
          if (item && typeof item.data === 'object' && field in item.data) {
            const value = String(item.data[field].value || item.data[field]);
            chartData[value] = (chartData[value] || 0) + 1;
          }
        });

        const formattedData: ChartDataItem[] = Object.entries(chartData).map(([name, value]) => ({
          name,
          value: Number(value),
        }));

        setData(formattedData);
      } catch (error) {
        console.error('Error fetching zone items:', error);
        if (error instanceof Error) {
          setError(`Error: ${error.message}`);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [zoneId, field]);

  if (isLoading) {
    return <div>Loading chart data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (data.length === 0) {
    return <div>No data available for the selected field: {field}</div>;
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsPieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="80%"
          dataKey="value"
          labelLine={false}
          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend 
        align="left"
        layout="horizontal"
        iconSize={10}
        wrapperStyle={{
          fontSize: '12px',
          paddingLeft: '0px',
        }}
      />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;