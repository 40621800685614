import { extendTheme, HTMLChakraProps, ThemingProps, ThemeComponentProps } from '@chakra-ui/react';
import { CardComponent } from './additions/card/card';
import { buttonStyles } from './components/button';
import { badgeStyles } from './components/badge';
import { inputStyles } from './components/input';
import { progressStyles } from './components/progress';
import { sliderStyles } from './components/slider';
import { textareaStyles } from './components/textarea';
import { switchStyles } from './components/switch';
import { linkStyles } from './components/link';
import { breakpoints } from './foundations/breakpoints';
import { globalStyles } from './styles';
import { tableStyles } from './components/table';
import { mode } from '@chakra-ui/theme-tools';

let theme = extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  badgeStyles, // badge styles
  buttonStyles, // button styles
  linkStyles, // link styles
  progressStyles, // progress styles
  sliderStyles, // slider styles
  inputStyles, // input styles
  textareaStyles, // textarea styles
  switchStyles, // switch styles
  CardComponent, // card component
  tableStyles, // table styles
  {
    components: {
      Select: {
        baseStyle: {
          field: {
            borderRadius: '16px',
          },
        },
        variants: {
          main: (props: ThemeComponentProps) => ({
            field: {
              bg: mode('transparent', 'navy.800')(props),
              border: '1px solid',
              color: mode('secondaryGray.900', 'white')(props),
              borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
              borderRadius: '16px',
              _hover: {
                borderColor: mode('orange.500', 'orange.300')(props),
              },
              _focus: {
                borderColor: mode('orange.500', 'orange.300')(props),
                boxShadow: mode('0 0 0 1px orange.500', '0 0 0 1px orange.300')(props),
              },
            },
            icon: {
              color: mode('secondaryGray.500', 'orange.500')(props),
            },
            option: {
              bg: mode('white', 'navy.800')(props),
              color: mode('secondaryGray.900', 'white')(props),
              _hover: {
                bg: mode('orange.100', 'orange.700')(props),
              },
              _focus: {
                bg: mode('orange.200', 'orange.600')(props),
              },
              _selected: {
                bg: mode('orange.300', 'orange.500')(props),
              },
            },
          }),
        },
        defaultProps: {
          variant: 'main',
        },
      },
    },
  }
);

export default theme;

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}