import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  VStack,
  Image,
  SimpleGrid,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Avatar,
  Badge,
  Progress,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
} from '@chakra-ui/react';
import axios, { AxiosResponse } from 'axios'; // Assuming axios is used for API calls
import Delete from '../settings/components/Delete';
import FileUpload from 'components/FileUpload';
import { FiMoreVertical } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';

interface ICompanyData {
  name: string;
  logo: string;
  userLimit: number;
  userCount: number;
  diskSpaceQuota: number;
  usedSpace: number;
  package: string;
}

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: 'Active' | 'Inactive' | 'Pending';
}

const CompanyPage = () => {
  const [companyData, setCompanyData] = useState<ICompanyData>({
    name: '',
    logo: '',
    userLimit: 0,
    userCount: 0,
    diskSpaceQuota: 0,
    usedSpace: 0,
    package: '',
  });
  const [newUserEmail, setNewUserEmail] = useState('');
  const toast = useToast();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCompany = async () => {
      const token = localStorage.getItem('token'); 
      axios.get(
        `${API_BASE_URL}/api/company/info`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      ).then((res: AxiosResponse<ICompanyData>) => {
        if(res && res.data){
          setCompanyData(res.data)
        }
      }).catch(err => {

      });
    }

    fetchCompany()
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${API_BASE_URL}/api/company/update`,
        { name: companyData.name, logo: companyData.logo },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        setCompanyData(response.data);
        toast({
          title: 'Company information updated',
          description: 'Your changes have been saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating company information:', error);
      toast({
        title: 'Update failed',
        description: 'There was an error updating the company information. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleInviteUser = async () => {
    if (companyData.userCount >= companyData.userLimit) {
      toast({
        title: 'User limit reached',
        description: 'You have reached the maximum number of users for your current plan.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/company/invite`, { email: newUserEmail }, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: 'Invitation sent',
          description: 'An invitation has been sent to the user.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        setUsers([...users, {
          _id: response.data.invitationId,
          firstName: '',
          lastName: '',
          email: newUserEmail,
          role: 'user',
          status: 'Pending'
        }]);

        setNewUserEmail('');
        setCompanyData({...companyData, userCount: companyData.userCount + 1});
      }
    } catch (error) {
      console.error('Error inviting user:', error);
      toast({
        title: 'Error',
        description: 'There was an error sending the invitation. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleLogoUpload = async (fileUrl: string) => {
    try {
      // Update local state
      setCompanyData(prev => ({ ...prev, logo: fileUrl }));
      
      // Make API call to update company info
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/company/update`,
        { name: companyData.name, logo: fileUrl },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Dispatch custom event to notify navbar
      window.dispatchEvent(new Event('companyLogoUpdated'));

      toast({
        title: 'Logo updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating logo:', error);
      toast({
        title: 'Error updating logo',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUploadError = (error: string) => {
    toast({
      title: 'Upload Error',
      description: error,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchUsersAndInvitations = async () => {
      try {
        const token = localStorage.getItem('token');
        const [usersResponse, invitationsResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/company/users`, {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${API_BASE_URL}/api/company/invitations`, {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        const activeUsers = usersResponse.data.map((user: any) => ({
          _id: user._id,
          ...user,
          status: 'Active'
        }));

        const pendingUsers = invitationsResponse.data.map((invitation: any) => ({
          _id: invitation._id,
          email: invitation.email,
          firstName: '',
          lastName: '',
          role: 'user',
          status: 'Pending'
        }));

        setUsers([...activeUsers, ...pendingUsers]);
      } catch (error) {
        console.error('Error fetching users and invitations:', error);
      }
    };

    fetchUsersAndInvitations();
  }, []);

  const handleResendInvitation = async (email: string) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_BASE_URL}/api/company/resend-invite`,
        { email },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast({
        title: 'Invitation resent!',
        description: 'The invitation has been resent to the user.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error resending invitation:', error);
      toast({
        title: 'Resend failed',
        description: 'There was an error resending the invitation. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleDeleteUser = async (userId: string) => {
    setUserToDelete(userId);
    setIsDeleteAlertOpen(true);
  };

  const confirmDeleteUser = async () => {
    if (!userToDelete) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(
        `${API_BASE_URL}/api/company/users/${userToDelete}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(users.filter(user => user._id !== userToDelete));
      toast({
        title: 'User deleted',
        description: 'The user has been removed from your company.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      toast({
        title: 'Delete failed',
        description: 'There was an error deleting the user. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteAlertOpen(false);
      setUserToDelete(null);
    }
  };

  const [isEditingLogo, setIsEditingLogo] = useState(false);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 5 }} spacing="20px" templateColumns={{ base: "1fr", md: "2fr 3fr" }}>
        <Card padding="20px" mb="20px">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex direction='column'>
              <Text fontSize='xl' color={textColor} fontWeight='bold'>
                Company Settings
              </Text>
              <Text fontSize='md' color={textColorSecondary}>
                Manage your company information
              </Text>
            </Flex>
            <Box width="150px" height="150px" position="relative">
              {companyData.logo && !isEditingLogo ? (
                <Box position="relative" width="100%" height="100%">
                  <Image 
                    src={companyData.logo.startsWith('http') 
                      ? companyData.logo 
                      : `${API_BASE_URL}${companyData.logo}`
                    }
                    alt="Company Logo" 
                    objectFit="contain"
                    width="100%" 
                    height="100%" 
                    borderRadius="md"
                    crossOrigin="anonymous"
                    p={2}
                    fallback={
                      <Box 
                        width="100%" 
                        height="100%" 
                        bg="gray.100" 
                        display="flex" 
                        alignItems="center" 
                        justifyContent="center"
                        borderRadius="md"
                      >
                        <Text color="gray.500" fontSize="sm">Logo</Text>
                      </Box>
                    }
                  />
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="blackAlpha.600"
                    borderRadius="md"
                    opacity="0"
                    transition="opacity 0.2s"
                    _hover={{ opacity: 1 }}
                    cursor="pointer"
                    onClick={() => setIsEditingLogo(true)}
                  >
                    <Icon as={MdEdit} color="white" w="24px" h="24px" />
                  </Box>
                </Box>
              ) : (
                <Box position="relative">
                  <FileUpload
                    onUploadSuccess={(fileUrl) => {
                      handleLogoUpload(fileUrl);
                      setIsEditingLogo(false);
                    }}
                    onUploadError={handleUploadError}
                    acceptedFileTypes={['image/*']}
                    maxFileSize={5 * 1024 * 1024}
                    uploadUrl={`${API_BASE_URL}/api/company/upload-logo`}
                  />
                  {isEditingLogo && (
                    <Button
                      position="absolute"
                      bottom="-35px"
                      left="50%"
                      transform="translateX(-50%)"
                      size="sm"
                      variant="outline"
                      colorScheme="gray"
                      onClick={() => setIsEditingLogo(false)}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Flex>
          <VStack spacing={6} align="stretch" px="10px" pb="20px">
  <FormControl>
    <FormLabel>Company Name</FormLabel>
    <Input
      name="name"
      value={companyData.name}
      onChange={handleInputChange}
    />
  </FormControl>
  <FormControl>
    <FormLabel>Package</FormLabel>
    <Input value={companyData.package} isReadOnly />
  </FormControl>
  <FormControl>
    <FormLabel>User Limit ({companyData.userCount}/{companyData.userLimit})</FormLabel>
    <Progress 
  width="250px"
  value={(companyData.userCount / companyData.userLimit) * 100} 
  colorScheme={companyData.userCount >= companyData.userLimit ? "red" : "green"}
  sx={{
    backgroundColor: 'gray.200'
}}
/>
  </FormControl>
  <FormControl>
    <FormLabel>Disk Space Quota ({(companyData.usedSpace / (1024 * 1024)).toFixed(2)}/{companyData.diskSpaceQuota} MB)</FormLabel>
    <Progress 
    width="250px"
      value={(companyData.usedSpace / (companyData.diskSpaceQuota * 1024 * 1024)) * 100} 
      colorScheme={companyData.usedSpace >= companyData.diskSpaceQuota * 1024 * 1024 ? "red" : "green"}
      sx={{
          backgroundColor: 'gray.200'
      }}
    />
  </FormControl>
  <Flex justifyContent="flex-end">
    <Button 
      onClick={handleSaveChanges} 
      colorScheme="brand" 
      size="sm"
    >
      Save Changes
    </Button>
  </Flex>
</VStack>
        </Card>

        <Flex direction="column">
          <Card padding="20px" mb="20px">
            <Text fontSize='xl' color={textColor} fontWeight='bold' mb='4'>
              User Management
            </Text>
            <Box overflowX="auto">
              <Table variant="simple" mb='6'>
                <Thead>
                  <Tr>
                    <Th
                      fontSize={{ sm: '14px', lg: '15px' }}
                      color='gray.700'
                      fontWeight='normal'
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                    >
                      Name
                    </Th>
                    <Th
                      fontSize={{ sm: '14px', lg: '15px' }}
                      color='gray.700'
                      fontWeight='normal'
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                    >
                      Email
                    </Th>
                    <Th
                      fontSize={{ sm: '14px', lg: '15px' }}
                      color='gray.700'
                      fontWeight='normal'
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                    >
                      Role
                    </Th>
                    <Th
                      fontSize={{ sm: '14px', lg: '15px' }}
                      color='gray.700'
                      fontWeight='normal'
                      borderWidth="1px"
                      borderColor={borderColor}
                      borderStyle="solid"
                    >
                      Status
                    </Th>
                    <Th
                     fontSize={{ sm: '14px', lg: '15px' }}
                     color='gray.700'
                     fontWeight='normal'
                     borderWidth="1px"
                     borderColor={borderColor}
                     borderStyle="solid"
                    >
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user) => (
                    <Tr key={user._id}>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        <Flex align="center">
                          <Avatar size="sm" name={user.status === 'Pending' ? '' : `${user.firstName} ${user.lastName}`} mr={2} />
                          <Text fontWeight="bold">
                            {user.status === 'Pending' ? 'Pending User' : `${user.firstName} ${user.lastName}`}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {user.email}
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {user.role}
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        <Badge colorScheme={user.status ? (user.status.toLowerCase() === 'active' ? 'green' : 'yellow') : 'gray'}>
                          {user.status || 'N/A'}
                        </Badge>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {user.role !== 'admin' && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label='Options'
                              icon={<FiMoreVertical />}
                              variant='outline'
                              size='sm'
                            />
                            <MenuList>
                              {(!user.status || user.status === 'Pending') && (
                                <MenuItem onClick={() => handleResendInvitation(user.email)}>
                                  Resend Invitation
                                </MenuItem>
                              )}
                              <MenuItem onClick={() => handleDeleteUser(user._id)}>
                                Delete User
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            
            <Text fontSize='xl' color={textColor} fontWeight='bold' mb='4'>
              Invite Users
            </Text>
            <Flex>
              <Input
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                placeholder="Enter email address"
                mr={2}
              />
              <Button 
                onClick={handleInviteUser} 
                colorScheme="brand" 
                variant="outline" 
                size="md"
                isDisabled={companyData.userCount >= companyData.userLimit}
              >
                Send Invite
              </Button>
            </Flex>
            
          </Card>

          <Card>
            <Delete />
          </Card>
        </Flex>
      </SimpleGrid>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this user? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteUser} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CompanyPage;